
import React, { useMemo, useState, useEffect } from 'react';
import { Box, Table, Tooltip, TableCell, Alert, Button, Stack, Grid, TableContainer, TableRow, Paper, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import RefreshIcon from '@mui/icons-material/Refresh';
import SendIcon from '@mui/icons-material/Send';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useParams, useNavigate, Link } from 'react-router-dom';
import AuthUser from "../Auth/AuthUser";
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Moment from 'moment';
import AiFillPhone from '@mui/icons-material/Phone';
import CommonFliter from './CommonFliter';
import LinkIcon from '@mui/icons-material/Link';
import config from '../../AppConfig';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useDispatch } from 'react-redux';
import ReSendIcon from '@mui/icons-material/SendToMobileOutlined';
import swal from "sweetalert";
import Backdrop from '@mui/material/Backdrop';
import Freez from '../Loader/Loader';
import ShareIcon from '@mui/icons-material/Share';
import { Formik } from 'formik';
import * as Yup from 'yup';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import formatPhoneNumber from '../../../src/Components/Formats/PhoneNumberValidation';
import {openOrFocusWindow } from  '../CommonFormula';

import { fetchData, postData } from '../../redux/apiSlice';



function createData(name, calories, fat, carbs, protein) {
	return { name, calories, fat, carbs, protein };
}

const rows = [
	createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
];

const RequestFormInitialValues = {
	emailId: '',
};

function Active(props) {
	const dispatch = useDispatch();
	const { tab } = useParams();
	const { http, user } = AuthUser();
	const [data, setData] = useState([]);
	const [originaldata, setOriginalData] = useState([]);
	const [rowSelection, setRowSelection] = useState({});
	const [search, setSearch] = useState("");
	const [disable, setDisable] = useState(false);
	const [statusMessage, setStatusMesage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [openEditEmailPopup, setOpenEditEmailPopup] = useState(false);
	const [email, setEmail] = useState('');
	const navigate = useNavigate();

	const [perPage, setPerPage] = useState(10);
	const [isLoading, setIsLoading] = useState(true);
	const q = search == '' ? 'null' : search;

	const [columnVisibility, setColumnVisibility] = React.useState({});

	const [refresh, doRefresh] = useState(0);
	const [freez, setFreez] = React.useState(false);
	const [checkUsaTldStatus, setCheckUsaTldStatus] = useState(false);
	const [emailButton, setEmailButton] = useState(false);
	const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
	const [deleteId, setDeleteId] = useState('');

	const handleFreezStop = () => {
		props.freezStop();
	};
	const handleFreezStart = () => {
		props.freezStart();
	};

	const customStatusSortFunction = (rowA, rowB) => {
		const sortA = rowA.original.reference_affiliate_status_master_id == 1 ? (rowA.original.email_sent == 0 ? "Email Not Sent" :  "Email Sent") : rowA.original.status ? rowA.original.status.status_name : '';
		const sortB = rowB.original.reference_affiliate_status_master_id == 1 ? (rowB.original.email_sent == 0 ? "Email Not Sent" :  "Email Sent") : rowB.original.status ? rowB.original.status.status_name : '';
		return sortA.localeCompare(sortB, undefined, { sensitivity: 'base' });
	};

	useEffect(() => {
		if (Object.values(columnVisibility).length > 0) {
			setColumnVisibility(columnVisibility);
		}
		//do something when the row selection changes...
	}, [], [rowSelection]);

	// Single Refresh To Get Details and Status From Pandadoc 
	const regenerateData = async (id) => {
		handleFreezStart();
		const apiUrl = '/regenerateAgreement';
		const args = {
			id: id
		};
		const res = await dispatch(postData({ apiUrl, args })).unwrap();
		if (res) {
			handleFreezStop();
			if (res.success === true) {
				swal({
                        title: "Success",
                        text: res.message,
                        icon: "success",
                        button: "Ok",
                })
				setTimeout(function () {
					doRefresh(prev => prev + 1);
					const dynamicId = `singleRefresh_` + id;
					const singleRefresh = document.getElementById(dynamicId);
					singleRefresh.style.display = "block";
					const dynamicId1 = `regenerated_` + id;
					const regenerated = document.getElementById(dynamicId1);
					regenerated.style.display = "none";
					setStatusMesage('');
				}, 3000);
			} else {
				handleFreezStop();
				swal({
					title: "Failed",
					text: res.message,
					icon: "error",
					button: "Ok",
				})
				if(res.message == "This action is unauthorized.") {
					navigate('/login');
				}
			}
		}
	}
	// 
	const singleRefresh = async (pandadoc_id, id, company_id) => {
		handleFreezStart();
		setDisable(true)
		const apiUrl = '/signleRefresh';
		const args = {
			document_id: pandadoc_id,
			affref_id: id,
			company_id: company_id
		}
		const res = await dispatch(postData({ apiUrl, args })).unwrap();
		if (res) {
			handleFreezStop();
			if (res.success === true) {
				swal({
					title: "Success",
					text: res.message,
					icon: "success",
					button: "Ok",
				})
				doRefresh(prev => prev + 1);
				setDisable(false)
				
			} else {
				if (res.message === 'expired') {
					swal({
						title: "",
						text: "Panda Doc has been Expired.Please Regenerate the Document.",
						icon: "warning",
						button: "Ok",
					})
					const dynamicId = `singleRefresh_` + id;
					const singleRefresh = document.getElementById(dynamicId);
					singleRefresh.style.display = "none";
					const dynamicId1 = `regenerated_` + id;
					const regenerated = document.getElementById(dynamicId1);
					regenerated.style.display = "block";
				} else {
					
					swal({
						title: "",
						text: res.message,
						icon: "warning",
						button: "Ok",
					})
					if(res.message == "This action is unauthorized.") {
						navigate('/login');
					}
					const dynamicId = `singleRefresh_` + id;
					const singleRefresh = document.getElementById(dynamicId);
					singleRefresh.style.display = "block";
					const dynamicId1 = `regenerated_` + id;
					const regenerated = document.getElementById(dynamicId1);
					regenerated.style.display = "none";

				}
				doRefresh(prev => prev + 1);
				setDisable(false)
			}
		}
	};

	const changeRowSelection = () => {
		setRowSelection([]);
	};

	// Send Agreement To Affiliate Using Pandadoc 
	const sendAgreement = async (id) => {
		handleFreezStart();
		setDisable(true)
		setStatusMesage('Sending Agreement...');
		const apiUrl = '/sendAgreement';
		const args = {
			id: id,
		}
		const res = await dispatch(postData({ apiUrl, args })).unwrap();
		if (res) {
			if (res.success === true) {
				doRefresh(prev => prev + 1);
				swal({
					title: "Success",
					text: res.message.message,
					icon: "success",
					button: "Ok",
				})
				setTimeout(function () {
					setStatusMesage('');
					setDisable(false)
				}, 3000);
			} else {
				doRefresh(prev => prev + 1);
				swal({
					title: "Failed",
					text: res.message.message,
					icon: "error",
					button: "Ok",
				})
				setDisable(false)
			}
		}
		handleFreezStop();
	};
	const resendEmail = async (id, email, firstName) => {
		handleFreezStart();
		const apiUrl = '/resendCRCEmail';
		const args = {
			id: id,
			email: email,
			firstName: firstName
		}
		const res = await dispatch(postData({ apiUrl, args })).unwrap();
		if (res) {
			if (res.success === true) {
				doRefresh(prev => prev + 1);
				swal({
					title: "Success",
					text: res.message,
					icon: "success",
					button: "Ok",
				})
				setDisable(false)
				
			} else {
				doRefresh(prev => prev + 1);
				swal({
					title: "Failed",
					text: res.message,
					icon: "error",
					button: "Ok",
				})
				setDisable(false)
				
			}
		}
		handleFreezStop();

	}

	// Resend Email Start
	const editEmailPopup = async (email) => {
		setEmail(email);
		setOpenEditEmailPopup(true);
	};
	const editEmailPopupClose = async () => {
		setCheckUsaTldStatus(false);
		setOpenEditEmailPopup(false);
	};
	const RequestFormSchema = Yup.object().shape({
		emailId: Yup.string()
			.matches(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24})$/i, "Invalid email format.")
			.required('Please enter your email address.'),

	})

	const emailEdit = async (values) => {
		if(checkUsaTldStatus == true){
			return false;
		}
		setOpenEditEmailPopup(false);
		handleFreezStart();
		const apiUrl = '/editEmailManageleads';
		const args = {
			oldEmail: email,
			newEmail: values.emailId,
		}
		const res = await dispatch(postData({ apiUrl, args })).unwrap();
		if (res) {
			if (res.success === true) {
				handleFreezStop();
				doRefresh(prev => prev + 1);
				swal({
					title: "Success",
					text: res.message,
					icon: "success",
					button: "Ok",
				})
			} else {
				handleFreezStop();
				doRefresh(prev => prev + 1);
				swal({
					title: "Failed",
					text: res.message,
					icon: "error",
					button: "Ok",
				  })
			}
			if(res.message == "This action is unauthorized.") {
				navigate('/login');
			}
		}

	}
	// Resend Email End

	const checkUsaTLD = async(email) => {
        if (email.includes('@')) {
			setEmailButton(true);
            const apiUrl = '/checkUsaTLD';
            const args = {
                email: email,
            }
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
            if (res) {
                if (res.success === true) {
                    setCheckUsaTldStatus(true);
                } else {
                    setCheckUsaTldStatus(false);
                }
            }
			setEmailButton(false);
        }
    }

	const getPandaDoc = async (id) => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  > 2) {
			navigate('/login');
			return false;
		}
		window.open(
			config.BASE_URL+"AffiliateAgreement/"+id+"/YWZmaWxpYXRl"+"/2" ,
						'_blank' // <- This is what makes it open in a new window.
					);
		
	}
	const getPandaDocNew = async (name) => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  > 2) {
			navigate('/login');
			return false;
		}
		const apiUrl = `/get-pdf/${name}`;
		window.open(config.API_BASE_URL+apiUrl); 
        // const res = await dispatch(fetchData(apiUrl)).unwrap();
		// if(res)
		// {	
		// 	if (res.success) {
		// 		window.open(config.API_URL+res.data.pdfUrl); 
		// 	}else {
		// 		swal({
		// 			title: "error",
		// 			text: res.message,
		// 			icon: "error",
		// 			button: "Ok",
		// 		})
		// 	}
		// }
	}
	const customSortFun = (rowA, rowB) => {
		
		const sortA = rowA.original.company.referred_by_company ? rowA.original.company.referred_by_company.company_name : rowA.original.company.referred_by != null ? rowA.original.company.referred_by : "";
		const sortB =rowB.original.company.referred_by_company ? rowB.original.company.referred_by_company.company_name : rowB.original.company.referred_by != null ? rowB.original.company.referred_by : "";
    	return sortA.localeCompare(sortB, undefined, { sensitivity: 'base' });
	};
				
	const deleteLog=async ()=>{
		setOpenConfirmationBox(false)
	  	handleFreezStart(true)
		const apiUrl = '/manageLeads/delete';
		const args = {
			id: deleteId,
		}
		const res = await dispatch(postData({ apiUrl, args })).unwrap();
	  	if(res) 
		{
			doRefresh(prev => prev + 1);
			handleFreezStop(false)
			if (res.success === true) {
				swal({
					title: "Success",
					text: res.message,
					icon: "success",
					button: "Ok",
				})
			} else {
				handleFreezStop(false)
				swal({
					title: "error",
					text: res.message,
					icon: "error",
					button: "Ok",
				})
			}
		}
	
	
	}
	const columns = useMemo(
		//column definitions...
		() => [
			{
				accessorFn: (data) => `${data.first_name.trim() + ' ' + data.last_name.trim()}`,
				id: 'name',
				header: 'Name',
				size: 50,
				enablePinning: true,
			},
			
			{
				accessorFn: (data) => `${data.email ? data.email.trim() : ''}`,
				enableClickToCopy: true,
				id: 'email',
				header: 'Email ID',
				size: 150,
				Cell: ({ renderedCellValue, row }) => (
					<>
						{row.original.email ?
							<span>
								{row.original.email.trim()}{row.original.reference_affiliate_status_master_id == 1 && (row.original.email_verified == 0 || row.original.email_verified == null) ? <Tooltip title="Email Not Verified" arrow><ErrorOutlineIcon color={'error'} /></Tooltip> : null}
								{user.roles_id == 6 ||user.roles_id == 13?null:
									<Tooltip title="Edit User" arrow>
										<EditOutlinedIcon
											onClick={() => { editEmailPopup(row.original.email) }}
										/>
									</Tooltip>
								}
							</span> : 'N/A'}
					</>
				),
			},
			{
				accessorKey: 'phone_no',
				accessorFn: (row) => `${row.phone_no ? formatPhoneNumber(row.phone_no.trim()) : 'N/A'}`,
				filterVariant: 'range',
				id: 'phoneNo',
				header: 'Phone no.',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					row.original.phone_no ?
						<div>
							{formatPhoneNumber(row.original.phone_no.trim())}
							&nbsp;&nbsp;
							<span>
								{row.original.current_hours != '' ?
									row.original.current_hours >= 8 && row.original.current_hours <= 19 ?
										<Tooltip title={row.original.current_time}><WbSunnyIcon color="warning"></WbSunnyIcon ></Tooltip> : <Tooltip title={row.original.current_time}><DarkModeIcon ></DarkModeIcon></Tooltip>
									: ''
								}
							</span>
							{user.roles_id == 13?null:
							<Tooltip title="Dial" arrow>
							<a onClick={(event) => {event.preventDefault(); openOrFocusWindow(config.CallHippoDialer+row.original.phone_no); }} >
								<AiFillPhone color="primary" />
							</a>
						</Tooltip>}
							
						</div> : 'N/A'
				),
			},
			{
				accessorFn: (data) => `${data.pandadoc_id}`,
				filterVariant: 'range',
				id: 'docId',
				header: 'Agreement ID',
				size: 50,
				//custom conditional format and styling
				Cell: ({ renderedCellValue, row }) => (
					<>
						{row.original.pandadoc_id ? (
							<span>
								{row.original.pandadoc_id}
								{user.roles_id == 6 || user.roles_id == 13? null :
									<Tooltip title="View Agreement" arrow>
										<LinkIcon color="primary" onClick={() => {
											{row.original.status.status_name=='Agreement Signed'?getPandaDocNew(row.original.company.company_name):getPandaDoc(row.original.pandadoc_id) }
										}} />
									</Tooltip>
								}
								{row.original.reference_affiliate_status_master_id === 2 ? <>
									{user.roles_id == 6 || user.roles_id == 13 ? null :
										<Tooltip title="share" arrow className="share" id="share" >
											<ShareIcon htmlColor="orange" sx={{ cursor: 'pointer' }} onClick={() => { regenerateData(row.original.company.id) }} />
										</Tooltip> 
									}
								</> : ''}

							</span>
						) : "N/A"}
					</>
				),
			},
			
			{
				accessorFn: (data) => `${data.status ? data.status : ''}`,
				accessorKey: 'status',
				filterVariant: 'range',
				id: 'status',
				header: 'Status',
				size: 50,
				sortingFn: customStatusSortFunction,
				Cell: ({ renderedCellValue, row }) => (
					<div>
						{
							row.original.reference_affiliate_status_master_id == 1
							?
							<Stack direction="row">
								{
									row.original.email_sent == 0
										?
										<Typography>Email Not Sent &nbsp;</Typography>
										:
										<Typography>Email Sent &nbsp;</Typography>
								}
								{user.roles_id == 6 || user.roles_id == 13? null :
									<Tooltip title="Resend" arrow>
										<SendIcon onClick={() => { resendEmail(row.original.id, row.original.email, row.original.first_name) }} />
									</Tooltip>
								}
							</Stack>
							:
							<Typography>{row.original.status ? row.original.status.status_name : ''}</Typography>
						}
					</div>
				),
				//custom conditional format and styling
			},
			{
				accessorFn: (data) => `${data.updated_at ? data.updated_at : ''}`,
				filterVariant: 'range',
				id: 'lastUpdated',
				header: 'Last Updated',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<div>
						{row.original.updated_at ? Moment(row.original.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : "N/A"}
					</div>
				),
				//custom conditional format and styling
			},
			{
				accessorFn: (data) => `${data.created_at ? data.created_at : ''}`,
				filterVariant: 'range',
				id: 'leadGeneratedDate',
				header: 'Lead Generated Date',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<div>
						{row.original.created_at ? Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : "N/A"}
					</div>
				),
				//custom conditional format and styling
			},
			{
				accessorFn: (data) => `${data.company ? data.company.company_name : 'N/A'}`,
				id: 'company_name',
				header: 'Company Name',
				size: 50,
			},
			{
				accessorFn: (data) => `${data.last_activity_logs ? data.last_activity_logs.type ? data.last_activity_logs.type : '' : ''}`,
				filterVariant: 'range',
				id: 'followup',
				header: 'Followup',
				size: 50,
				//custom conditional format and styling
				Cell: ({ renderedCellValue, row }) => (
					<>
						{row.original.last_activity_logs ? row.original.last_activity_logs.type ? row.original.last_activity_logs.type : "N/A" : "N/A"}
					</>
				),
			},
			{
				accessorFn: (data) => `${data.company.referred_by_company ?
					data.company.referred_by_company.company_name :
					data.company.referred_by != null ? data.company.referred_by || '' : ''}`,
				filterVariant: 'range',
				id: 'leadType',
				header: 'Lead Type',
				sortingFn: customSortFun,
				size: 50,
				//custom conditional format and styling
				Cell: ({ renderedCellValue, row }) => (
					<div>
						{row.original.company.referred_by_company ?
							row.original.company.referred_by_company.company_name :
							row.original.company.referred_by !== null ? row.original.company.referred_by : "N/A"}
					</div>
				),
			},
			{
				accessorFn: (data) => `${data.company
					? data.company.marketing_type !== null
						? data.company.marketing_type
						: ''
					: ''}`,
				filterVariant: 'range',
				id: 'softwareUsed',
				header: 'Software Used',
				size: 50,
				//custom conditional format and styling
				Cell: ({ renderedCellValue, row }) => (
					<div>
						{row.original.company ? (row.original.company.marketing_type !== null ? row.original.company.marketing_type : 'N/A') : 'N/A'}
					</div>
				),
			},
			{
				accessorFn: (data) => `${data.last_activity_logs ? data.last_activity_logs.notes.trim() : ''}`,
				filterVariant: 'range',
				id: 'comment',
				header: 'Comment',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<Box className="datatable">
						<Typography>{row.original.last_activity_logs ? row.original.last_activity_logs.notes.trim() : 'N/A'}</Typography>
					</Box>
				),
			},
			{
				accessorFn: (data) => `${data.last_activity_logs ? data.last_activity_logs.add_next_followup : ''}`,
				filterVariant: 'range',
				id: 'nextFollowup',
				header: 'Next Followup',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<div>
						{row.original.last_activity_logs && row.original.last_activity_logs.add_next_followup !== "0000-00-00 00:00:00" && row.original.last_activity_logs.add_next_followup !== null
							? Moment(row.original.last_activity_logs.add_next_followup).format('MM-DD-YYYY H:mm')
							: "N/A"
						}
					</div>
				),
				//custom conditional format and styling
			},
			{
				accessorFn: (data) => `${data.lead_assigned_user ? data.lead_assigned_user.name.trim() : ''}`,
				filterVariant: 'range',
				id: 'assignedTo',
				header: 'Assigned To',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<div>
						{row.original.lead_assigned_user ? row.original.lead_assigned_user.name.trim() : 'N/A'
						}
					</div>
				),
			},
			{
				accessorFn: (data) => <>
					{ user.roles_id=== 13 ?
                ( <Tooltip title="Delete" arrow >
                  <img src={require('../../assets/icons/deleteIcon2.png')}  onClick={()=>{setDeleteId(data.company_master_id);setOpenConfirmationBox(true)}}/>
                  </Tooltip>):
				(user.roles_id == 6 ? null :
					<Stack direction="row">
						<Tooltip title="Affiliate Details" arrow><Link className='btn btn-sm btn-info text-white' to={{ pathname: "/crcreport/" + data.id }} ><img src={require('../../assets/images/details.png')} /></Link></Tooltip>&nbsp;
						{data.reference_affiliate_status_master_id === 2 ? <> <div id={`singleRefresh_${data.id}`}><Tooltip title="Refresh" arrow className="singleRefresh" id="singleRefresh" ><RefreshIcon className='btn btn-success text-white' sx={{ cursor: 'pointer' }} onClick={() => {doRefresh(prev => prev + 1)}} /></Tooltip> </div> <div id={`regenerated_${data.id}`} style={{ display: 'none' }}><Tooltip title="regenerate" arrow className="regenerate" id="regenerate" ><ReSendIcon htmlColor="orange" sx={{ cursor: 'pointer' }} onClick={() => { regenerateData(data.company.id) }} /></Tooltip> </div></> : ''}
						{data.reference_affiliate_status_master_id === 3 ? <><Tooltip title="Approve Now" arrow><Link className='btn btn-success text-white' to={{ pathname: "/crcreport/approve-now/" + data.id }} ><img src={require('../../assets/images/approveagreement.png')} /></Link></Tooltip></> : ''}
					</Stack>)
				}
				</>,
				id: 'actions',
				header: 'Actions',
				enableSorting: false
			},
		]
	)
	//csv option start here
	const csvOptions = {
		fieldSeparator: ',',
		quoteStrings: '"',
		decimalSeparator: '.',
		showLabels: true,
		useBom: true,
		useKeysAsHeaders: false,
		fileName: 'Active Leads',
		headers: ['Name', 'Email ID', 'Phone No.', 'Agreement ID', 'AID', 'status', 'Last Updated', 'Lead Generated Date','Company Name', 'Followup', 'Lead Type', 'Software Used', 'Comment', 'Next Followup', 'Assigned To'],
	};

	const csvExporter = new ExportToCsv(csvOptions);
	const handleExportRows = (rows) => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id > 2 && user.roles_id != 13) {
			navigate('/login');
			return false;
		}
		const exportedData = rows.map((row) => {
			const leadType =
			row.original.company &&
			(row.original.company.referred_by_company
			? row.original.company.referred_by_company.company_name
			: row.original.company.referred_by != null
			? row.original.company.referred_by
			: 'N/A');
			return {
				name: `${row.original.first_name} ${row.original.last_name}` || "N/A",
				email_id: `${row.original.email}` || "N/A",
				phone: `${formatPhoneNumber(row.original.phone_no)}` || "N/A",
				doc_id: `${row.original.pandadoc_id?row.original.pandadoc_id:"N/A"}` || "N/A",
				aid: `${(row.original.company) ? (row.original.company.aid_master) ? row.original.company.aid_master.aid : 'N/A' : 'N/A'}`,
				status: `${row.original.status ? row.original.status.status_name : 'N/A'}`,
				last_updated: `${Moment(row.original.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm')}` || "N/A",
				lead_generated_date: `${Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm')}` || "N/A",
				company_name: `${row.original.company ? row.original.company.company_name : 'N/A'}`,
				followup: row.original.last_activity_logs ? (row.original.last_activity_logs.type ? row.original.last_activity_logs.type : "N/A") : "N/A",
				lead_type:leadType,	
				software_used: `${row.original.company ? (row.original.company.marketing_type !== null ? row.original.company.marketing_type : 'N/A') : 'N/A'}`,
				Comment: `${row.original.last_activity_logs ? (typeof row.original.last_activity_logs.notes === 'string' ? row.original.last_activity_logs.notes.trim() : 'N/A') : 'N/A'}`,
				next_followup: `${row.original.last_activity_logs && row.original.last_activity_logs.add_next_followup !== "0000-00-00 00:00:00" && row.original.last_activity_logs.add_next_followup !== null ? Moment(row.original.last_activity_logs.add_next_followup).format('MM-DD-YYYY H:mm') : "N/A"}`,
				assigned_to: `${row.original.lead_assigned_user ? row.original.lead_assigned_user.name : 'N/A'}`,
			};
		});
		// Convert data to CSV format
		const csvContent = convertToCSV(exportedData);

		// Create a Blob object from the CSV data
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

		// Create a download link element
		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		link.download = csvOptions.fileName + '.csv';

		// Append the link to the document body
		document.body.appendChild(link);

		// Programmatically click the link to trigger the file download
		link.click();

		// Clean up the temporary URL and remove the link from the document
		URL.revokeObjectURL(link.href);
		link.remove();
	};

	const convertToCSV = (data) => {
		const headers = csvOptions.headers.join(',') + '\n';
	  
		const rows = data.map((row) => {
			return Object.values(row).map((value) => {
			  // Handle any necessary formatting or escaping of values
			  // For simplicity, we assume all values are already properly formatted
		
			  // Replace newline characters with spaces
			  if (typeof value === 'string') {
				value = value.replace(/\n/g, ' '); // Replace newline characters with spaces
			  }
		
			  // Wrap the value with double quotes if it contains a comma or quote
			  if (typeof value === 'string' && (value.includes(',') || value.includes('"'))) {
				return `"${value.replace(/"/g, '""')}"`; // Escape double quotes by doubling them
			  }
		
			  return value;
			}).join(',');
		  }).join('\n');
		
		  return headers + rows;
		};
	//csv end here


	const handleReferredList = (data) => {
		setData(data);
		setOriginalData(data);
		setIsLoading(false);
		props.freezStop();
	}

	const startLoading = () => {
		setIsLoading(true);
		props.freezStart();
	}

	const handleTablePreferences = (data) => {
		if (Object.values(data).length > 0) {
			setColumnVisibility(data);
		}
		// setIsLoading(false);
		props.freezStop();
	}

	const changeStatusFilter = (status) => {
		var filteredData = [];
		switch(status) {
			case '0' :filteredData = originaldata; break;
			case '6' : 
					   filteredData = originaldata.filter((item) =>item.reference_affiliate_status_master_id == 1 ? item.email_sent != 0 ? item : '' : '');
					   break;
			case '7' : 
						filteredData = originaldata.filter((item) =>item.reference_affiliate_status_master_id == 1 ? item.email_sent == 0 ? item : '' : '');
					   break;
			case '2' : 
						filteredData = originaldata.filter((item) =>item.reference_affiliate_status_master_id != 1 ? item.status.status_name == "Agreement Generated" ? item : '' : '');
						break;
			case '3':
						filteredData = originaldata.filter((item) =>item.reference_affiliate_status_master_id != 1 ? item.status.status_name == "Agreement Signed" ? item : '' : '');
						break;
		}
		setData(filteredData);
	}


	return (
		<>
			<Box pb={2} sx={{ width: '100%' }}>
				{statusMessage ? <Alert severity="success">{statusMessage}</Alert> : ''}
				{errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
			</Box>
			<CommonFliter http={http} leadType={tab} perPage={perPage}
				rowSelection={rowSelection}
				onDataChange={handleReferredList}
				onTablePreferences={handleTablePreferences}
				refresh={refresh}
				userId={user.id}
				columnArray={columnVisibility}
				changeRowSelection={changeRowSelection}
				startLoading={startLoading}
				onChangeStatusFilter= {changeStatusFilter}
			/>
			<Grid mt={2} item>
				<MaterialReactTable
					columns={columns}
					data={data}
					filterFns={{
						customSearchFilterFn: (row, id, filterValue) =>
						row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
					  }}                                   
					globalFilterFn="customSearchFilterFn" //exact serach filter functionality
					enableColumnFilterModes
					enablePinning
					enableColumnFilters={config.DatatableColumnFilter}
					enableRowSelection={user.roles_id != 13}
					getRowId={(row) => row.id} //give each row a more useful id
					onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
					state={{ columnVisibility, rowSelection, isLoading: isLoading }} //pass our managed row selection state to the table to use
					initialState={{
						showColumnFilters: false,
						density: 'compact',
						columnPinning: { left: ['mrt-row-expand','mrt-row-select', 'name'] }
					}}
					onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
					positionToolbarAlertBanner="bottom"
					enableDensityToggle={false}
					muiTableHeadCellProps={{
						//simple styling with the `sx` prop, works just like a style prop in this example
						sx: {
							backgroundColor: "#F6FAFF",
						},
					}}
					renderDetailPanel={({ row }) => (
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-around',
								alignItems: 'center',
							}}
						>
							<TableContainer component={Paper}>
								<Table className='back'>
									<TableRow>
										<TableCell style={{ fontWeight: 'bold' }}>Name:</TableCell>
										<TableCell>{row.original.first_name + ' ' + row.original.last_name}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Email:</TableCell>
										<TableCell>{row.original.email}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Phone No:</TableCell>
										<TableCell>{formatPhoneNumber(row.original.phone_no)}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell style={{ fontWeight: 'bold' }}>Agreement ID:</TableCell>
										<TableCell>{row.original.pandadoc_id}
											{user.roles_id == 6 ||user.roles_id == 13 ? null :
												<Tooltip title="Open Agreement" arrow><LinkIcon color="primary" onClick={() => {
													{row.original.status.status_name=='Agreement Signed'?getPandaDocNew(row.original.company.company_name):getPandaDoc(row.original.pandadoc_id) }
												}} /></Tooltip>
											}
											{row.original.reference_affiliate_status_master_id === 2 ? <>
												{user.roles_id == 6 ||user.roles_id == 13? null :
													<Tooltip title="share" arrow className="share" id="share" ><ShareIcon htmlColor="orange" sx={{ cursor: 'pointer' }} onClick={() => { regenerateData(row.original.company.id) }} /></Tooltip>
												}
											</> : ''}
										</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Status:</TableCell>
										<TableCell>{row.original.status.status_name}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Last Updated:</TableCell>
										<TableCell>{Moment(row.original.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm')}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell style={{ fontWeight: 'bold' }}>Lead Generated Date:</TableCell>
										<TableCell>{Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm')}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Followup:</TableCell>
										<TableCell>{(row.original.last_activity_logs ? (row.original.last_activity_logs.type ? row.original.last_activity_logs.type : 'N/A') : 'N/A')}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Lead Type:</TableCell>
										<TableCell>{row.original.company.referred_by_company ? row.original.company.referred_by_company.company_name : row.original.company.referred_by != null ? row.original.company.referred_by : "N/A"}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell style={{ fontWeight: 'bold' }}>Software Used:</TableCell>
										<TableCell>{row.original.company ? row.original.company.marketing_type : 'N/A'}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Comments:</TableCell>
										<TableCell>{row.original.last_activity_logs ? row.original.last_activity_logs.notes : 'N/A'}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Next Followup:</TableCell>
										<TableCell>{row.original.last_activity_logs ? row.original.last_activity_logs.add_next_followup : 'N/A'}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell style={{ fontWeight: 'bold' }}>Assigned To:</TableCell>
										<TableCell>{row.original.lead_assigned_user ? row.original.lead_assigned_user.name : 'N/A'}</TableCell>
									</TableRow>
								</Table>
							</TableContainer>
						</Box>
					)}
					//top csv option
					renderTopToolbarCustomActions={({ table }) => (
						<Box
							sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
						>
							<Button
								disabled={table.getPrePaginationRowModel().rows.length === 0}
								//export all rows, including from the next page, (still respects filtering and sorting)
								onClick={() =>
								  handleExportRows(table.getPrePaginationRowModel().rows)
								}
								startIcon={<FileDownloadIcon />}
								variant="contained"
							>
								csv
							</Button>

						</Box>
					)
					}
				/>
			</Grid>
			{/* Resend Email Process start here */}

			<Dialog
				open={openEditEmailPopup}
				onClose={(_, reason) => {
					if (reason !== "backdropClick") {
						editEmailPopupClose();
					}
				}}
				aria-labelledby="responsive-dialog-title"
				maxWidth={'sm'}
			>
				<Formik
					initialValues={RequestFormInitialValues}
					validationSchema={RequestFormSchema}
					onSubmit={emailEdit}
				>{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (<>
					<DialogTitle id="responsive-dialog-title">
						<Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
							<Grid item lg={9} md={9} sm={9} xs={9} >
								<Typography variant="h5">Edit Email Address</Typography>
							</Grid>
						</Grid>
					</DialogTitle>
					<DialogContent>
						<Grid container item lg={12} md={12} sm={12} xs={12}>
							<TextField
								name='emailId'
								id='emailId'
								label="Email"
								value={values.emailId}
								variant="outlined"
								size="small"
								fullWidth
								margin='dense'
								onChange={(e)=>{
									handleChange(e);
									checkUsaTLD(e.target.value);
								}}
							/>
							<div className='error'>{touched.emailId && errors.emailId ?errors.emailId : checkUsaTldStatus ? "Invalid email format." : '' }</div>  
						</Grid>
					</DialogContent>
					<DialogActions>
						<Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} sx={{ textAlign: "center", width: "100%" }}>
							<Grid item lg={6} md={6} sm={6} xs={6}>
								<Button variant="contained" onClick={editEmailPopupClose} sx={{ minWidth: 200 }}>
									Cancel
								</Button>
							</Grid>
							<Grid item lg={6} md={6} sm={6} xs={6}>
								<Button variant="contained" onClick={handleSubmit} color="success" sx={{ minWidth: 200 }} disabled={emailButton}>
									Update
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				</>
				)}
				</Formik>
			</Dialog>
			{/* Resend Email Process end here */}
			<Dialog
                                fullWidth
                                maxWidth={'sm'}
                                open={openConfirmationBox}
                                onClose={(_, reason) => {
                                    if (reason !== "backdropClick") {
                                        setOpenConfirmationBox(false);
                                    }
                                }}
                            >
                 
                                <DialogContent>
                                    <Box mt={1} sx={{textAlign:'center'}}>
                                        <Typography variant='h5'>Are you sure you want to delete this lead?</Typography>
                                    </Box>
                                </DialogContent>
                                <DialogActions sx={{ margin: 'auto' }} >
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" onClick={()=>{deleteLog()}} color="success" sx={{ minWidth: 250 }} >
                                            Delete
                                        </Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" onClick={() => setOpenConfirmationBox(false)} color="primary" sx={{ minWidth: 250 }}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                </DialogActions>
                    </Dialog>
			{/* loader code start */}
			<div>
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={freez}
					onClick={handleFreezStop}
				>
					<Freez />
				</Backdrop>
			</div>
			{/* loader code end */}
		</>
	);

}


export default Active;