import React, { useMemo, useState, useEffect } from 'react';
//MRT Imports
import MaterialReactTable from 'material-react-table';
//Material-UI Imports
import {
  Box,
  Button,
  MenuItem, Tooltip, Dialog, DialogActions, DialogTitle, DialogContent, Grid, FormControl, InputLabel, Select, Typography, Stack
} from '@mui/material';
import config from '../../AppConfig';
//Icons Imports
import AuthUser from '../Auth/AuthUser';
import { Link,useNavigate } from 'react-router-dom';
import AiFillPhone from '@mui/icons-material/Phone';
import Moment from 'moment';
import swal from "sweetalert";
import {  useDispatch } from 'react-redux';
import { fetchData, postData } from '../../redux/apiSlice';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import formatPhoneNumber from '../../../src/Components/Formats/PhoneNumberValidation';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import {openOrFocusWindow } from  '../CommonFormula';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function Pending(props) {

  const { http, user } = AuthUser();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [assigedUser, setAssigedUser] = React.useState();
  const [retentionId, setRetentionId] = React.useState();
  const [users, setUsers] = useState([]);
  const [assignedTo, setAssignedTo] = React.useState(props.assignTo);
  const [reloadpage, setReloadpage] = useState(props.reloadpage);
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = React.useState({});
  //   const rowsSeleted = Object.keys(rowSelection);
  const [companyId, setCompanyId] = React.useState(props.companyId);
  const dispatch = useDispatch();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const navigate = useNavigate();
  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value,
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };
  const tablePreferencesArray = {
    plan: false,
    phone_no: false,
    subscriptiondate: false,
    closedate: false,
    lastupdated: false,
    nextfollowup: false,
    recentnote: false,
  }

 
  useEffect(() => {
    if (props.tableData && props.tableData.length > 0) {
      setData(props.tableData);
      setUsers(props.user)
    }
    else{
      setData([]);
    }
    
    setIsLoading(false);
  }, [props.tableData])
  useEffect(() => {
    setRowSelection([]);
  }, [props.refresh,props.assignTo]);

  useEffect(() => {
    if (Object.values(columnVisibility).length > 0) {
      setColumnVisibility(columnVisibility);
    }
    //do something when the row selection changes...
    props.setRows(rowSelection);
    // console.info({ rowSelection });
  }, [rowSelection]);

  useEffect(() => {
    tablePreferences();
  }, []);

  useEffect(() => {
    saveTablePreferences();
  }, [columnVisibility])

  const tablePreferences = async () => {
    const apiUrl = '/fetchTablePreferences/pendingMemberlist';
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      if (Object.values(res).length == 0) {
        setColumnVisibility(tablePreferencesArray);
      } else {
        setColumnVisibility((JSON.parse(res.preferences)));
      }
    }
  }

  const saveTablePreferences = async () => {
    if (Object.values(columnVisibility).length > 0) {
      const apiUrl = '/saveTablePreferences';
      const args = {
        columnArray: columnVisibility,
        userId: user.id,
        tableId: 'pendingMemberlist'
      }
      const res = await dispatch(postData({ apiUrl, args })).unwrap();
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const reAssignUser = (retentionId, userId) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id < 3 ) {
      setAssigedUser(userId);
      setRetentionId(retentionId);
      handleClickOpen();
    }else {
      navigate('/login');
      return false;
    }

  };

  const ChangeAssignedUser = async () => {
    const apiUrl = '/reassign-user-for-retention-member';
    const args = {
      retentionId: retentionId,
      assigedUser: assigedUser,
    }

    const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if (res) {
      if (res.success === true) {
        setOpen(false);
        swal({
          title: "Info",
          text: res.message,
          icon: "info",
          button: "Ok",
        })
        // setIsLoading(true);
        props.freezStart();
        props.getData();
      } else {
        console.log('else');
      }
    }
  }

  const singleRefresh = async (memberId) => {
    props.freezStart();
    const apiUrl = '/retainMember/' + memberId;
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      if (res.success === true) {
        setOpen(false);
        swal({
          title: "Info",
          text: res.message,
          icon: "info",
          button: "Ok",
        })
        props.getData();
      } else {
        console.log('else');
      }
    }
  }
  //   useEffect(() => {
  //     if (Object.values(columnVisibility).length > 0) {
  //       setColumnVisibility(columnVisibility);
  //     }
  //     // props.setRows(rowSelection);     //need for row selection
  //   }, [rowSelection]);

  const handleClick = (phone_no) => {
    window.open(`${config.CallHippoDialer}${phone_no}`, 'CallHippo Dialer');
  };
  
  const columns = useMemo(
    () => [
      {
        accessorFn: (data) => `${data.member_id ? data.member_id : ''}`,
        accessorKey: 'member_id',
        header: 'Member ID',
        width: 100,
        enablePinning: true,
        enableClickToCopy: false,
        Cell: ({ renderedCellValue, row }) => (row.original.member_id ? row.original.member_id : "N/A"),
      },
      {
        accessorFn: (data) => `${data.name ? data.name.trim() : ''}`,
        accessorKey: 'name', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'Name',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (row.original.name ? row.original.name.trim() : 'N/A'),
      },
      {
        accessorFn: (data) => `${data.email ? data.email.trim() : ''}`,
        accessorKey: 'email', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'Email',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (row.original.email ? row.original.email.trim() : 'N/A'),
      },
      {
        accessorKey: 'PID', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'PID',
        width: 100,
      },
      {
        accessorFn: (data) => `${data.AID ? data.AID.trim() : ''}`,
        accessorKey: 'AID', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'AID',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (row.original.AID ? row.original.AID.trim() : "N/A"),
      },
      {
        accessorFn: (data) => `${data.plan ? data.plan.trim() : ''}`,
        accessorKey: 'plan', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'Plan',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (row.original.plan ? row.original.plan.trim() : "N/A"),
      },
      {
        accessorKey: 'phone_no',
        accessorFn: (row) => `${row.phone_no}`,
        filterVariant: 'range',
        id: 'phoneNo',
        header: 'Phone no.',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (
          row.original.phone_no ?
          <div>
            {row.original.phone_no ? formatPhoneNumber(row.original.phone_no.trim()) : "N/A"}
            &nbsp;&nbsp;
            <span>
              {row.original.current_hours != '' ?
                row.original.current_hours >= 8 && row.original.current_hours <= 19 ?
                  <Tooltip title={row.original.current_time}><WbSunnyIcon color="warning"></WbSunnyIcon ></Tooltip> : <Tooltip title={row.original.current_time}><DarkModeIcon ></DarkModeIcon></Tooltip>
                : ''
              }
            </span>
            <Tooltip title="Dial" arrow>
              <a onClick={(event) => {event.preventDefault(); openOrFocusWindow(config.CallHippoDialer+row.original.phone_no); }} >
                <AiFillPhone color="primary" />
              </a>
            </Tooltip>
          </div>: 'N/A'
        ),
      },
      {
        accessorFn: (data) => `${data.subscription_date ? data.subscription_date : ''}`,
        // `${data.subscription_date ? Moment(data.subscription_date).format('MM-DD-YYYY H:mm') + " Hrs" : ''}`,
        enableClickToCopy: false,
        header: 'Subscription Date',
        width: 100,
        id: 'subscriptiondate',
        Cell: ({ renderedCellValue, row }) => (
          <div >
            {row.original.subscription_date ? Moment(row.original.subscription_date).format('MM-DD-YYYY H:mm') + "Hrs" : "N/A"}
          </div>
        ),
      },

      {
        accessorFn: (data) => `${data.close_date ? data.close_date : ''}`,
        // `${data.close_date ? Moment(data.close_date).format('MM-DD-YYYY') : ''}`,
        enableClickToCopy: false,
        header: 'Close Date',
        width: 100,
        id: 'closedate',
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {row.original.close_date ? Moment(row.original.close_date).format('MM-DD-YYYY') : "N/A"}
          </div>
        ),
      },

      {
        accessorFn: (data) => `${data.activity_log ? data.activity_log.created_at : ''}`,
        // `${data.activity_log ? Moment(data.activity_log.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY hh:mm:ss') : ''}`,
        enableClickToCopy: false,
        header: 'Last Updated',
        width: 100,
        id: 'lastupdated',
        Cell: ({ renderedCellValue, row }) => (
          <div >
            {row.original.activity_log ? Moment(row.original.activity_log.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY hh:mm:ss') : "N/A"}
          </div>
        ),
      },
      {
        accessorFn: (data) => `${data.activity_log ? (data.activity_log.next_follow_up != null && data.activity_log.next_follow_up != '0000-00-00 00:00:00' ? Moment(data.activity_log.next_follow_up).format('MM-DD-YY hh:mm') : (data.activity_log.add_next_followup ? Moment(data.activity_log.add_next_followup).format('MM-DD-YY hh:mm') : 'N/A')) : 'N/A'}`,  //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'Next Follow Up',
        width: 100,
        id: 'nextfollowup',

      },
      {
        accessorFn: (data) => `${data.activity_log ? data.activity_log.notes ? data.activity_log.notes.trim() : '' : ''}`,  //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'Recent Note',
        width: 100,
        id: 'recentnote',
        Cell: ({ renderedCellValue, row }) => (
          row.original.activity_log ? row.original.activity_log.notes ? row.original.activity_log.notes.trim() : 'N/A' : 'N/A'
        ),
      },
      {
        accessorFn: (data) => `${data.user ? data.user.first_name.trim() + " " + data.user.last_name.trim() : ''}`,  //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        id: 'assign_to',
        header: 'Assigned To',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (
          <>
            {row.original.user ? row.original.user.first_name.trim() + " " + row.original.user.last_name.trim() : 'N/A'}
          </>
        ),
      },
      {
        accessorFn: (data) =>
          <>
            <Stack direction="row"><span>Closed/Pending</span> &nbsp;
              <Tooltip title="Refresh Status" arrow><Link onClick={() => { singleRefresh(data.member_id) }}><img src={require('../../assets/icons/Refresh.png')} /></Link></Tooltip>
            </Stack>
          </>,
        enableClickToCopy: false,
        id: 'status',
        header: 'Status',
        width: 100,
      },
      {
        accessorFn: (data) =>
          <>
            <Stack direction="row">
              <Link className='btn btn-sm btn-info text-white' to={{ pathname: "/member-activity/" + data.member_id }} ><Tooltip title="Start Activity" arrow><img src={require('../../assets/images/start-activity.png')} /></Tooltip></Link>
              {data.user ?
                <Button onClick={() => { reAssignUser(data.id, data.assign_to) }} ><Tooltip title="ReAssign" arrow><img src={require('../../assets/images/reactivate.png')} /></Tooltip></Button>
                : ''}
            </Stack>
          </>,
        enableClickToCopy: false,
        id: 'action',
        header: 'Action',
        width: 100,
      },

    ],
    [],
  );

  const columns2 = useMemo(
    () => [
      {
        accessorFn: (data) => `${data.member_id ? data.member_id : ''}`,
        accessorKey: 'member_id',
        header: 'Member ID',
        width: 100,
        enablePinning: true,
        Cell: ({ renderedCellValue, row }) => (row.original.member_id ? row.original.member_id : "N/A"),
      },
      {
        accessorFn: (data) => `${data.name ? data.name.trim() : ''}`,
        accessorKey: 'name', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'Name',
        width: 100,
        sortingFn: (rowA, rowB) => {
          const nameA = rowA.original.name.toLowerCase();
          const nameB = rowB.original.name.toLowerCase();
          return nameA.localeCompare(nameB);
        },
        Cell: ({ renderedCellValue, row }) => (row.original.name ? row.original.name.trim() : 'N/A'),
      },
      {
        accessorFn: (data) => `${data.email ? data.email.trim() : ''}`,
        accessorKey: 'email', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'Email',
        width: 100,
        sortingFn: (rowA, rowB) => {
          const emailA = rowA.original.email.toLowerCase();
          const emailB = rowB.original.email.toLowerCase();
          return emailA.localeCompare(emailB);
        },
        Cell: ({ renderedCellValue, row }) => (row.original.email ? row.original.email.trim() : 'N/A'),
      },
      {
        accessorKey: 'PID', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: true,
        header: 'PID',
        width: 100,
      },
      {
        accessorFn: (data) => `${data.AID ? data.AID.trim() : ''}`, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'AID',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (row.original.AID ? row.original.AID.trim() : "N/A"),
      },
      {
        accessorFn: (data) => `${data.plan ? data.plan.trim() : ''}`,
        accessorKey: 'plan', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: false,
        header: 'Plan',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (row.original.plan ? row.original.plan.trim() : "N/A"),
      },
      {
        accessorKey: 'phone_no',
        accessorFn: (row) => `${row.phone_no}`,
        filterVariant: 'range',
        id: 'phoneNo',
        header: 'Phone no.',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {row.original.phone_no ? formatPhoneNumber(row.original.phone_no.trim()) : "N/A"}
            &nbsp;&nbsp;
            <span>
              {row.original.current_hours != '' ?
                row.original.current_hours >= 8 && row.original.current_hours <= 19 ?
                  <Tooltip title={row.original.current_time}><WbSunnyIcon color="warning"></WbSunnyIcon ></Tooltip> : <Tooltip title={row.original.current_time}><DarkModeIcon ></DarkModeIcon></Tooltip>
                : ''
              }
            </span>
          </div>
        ),
      },
      {
        accessorFn: (data) => `${data.subscription_date ? data.subscription_date : ''}`,
        // `${data.subscription_date ? Moment(data.subscription_date).format('MM-DD-YYYY H:mm') + " Hrs" : ''}`,
        enableClickToCopy: false,
        header: 'Subscription Date',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (
          <div >
            {row.original.subscription_date ? Moment(row.original.subscription_date).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') + " Hrs" : "N/A"}
          </div>
        ),
      },

      {
        accessorFn: (data) => `${data.close_date ? data.close_date : ''}`,
        // `${data.close_date ? Moment(data.close_date).format('MM-DD-YYYY') : ''}`,
        enableClickToCopy: false,
        header: 'Close Date',
        width: 100,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            {row.original.close_date ? Moment(row.original.close_date).format('MM-DD-YYYY') : "N/A"}
          </div>
        ),
      },
      {
        accessorFn: (data) =>
          <><span>Closed/Pending</span>&nbsp;&nbsp;
            <Tooltip title="Refresh Status" arrow><Link onClick={() => { singleRefresh(data.member_id) }}><img src={require('../../assets/icons/Refresh.png')} /></Link></Tooltip>
          </>,
        enableClickToCopy: false,
        id: 'status',
        header: 'Status',
        width: 100,
      },
    ],
    [],
  );
  const handleExportData = (rows) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if (loginUser.roles_id != 1 && loginUser.roles_id != 2 && loginUser.roles_id != 3 && loginUser.roles_id != 4 && loginUser.roles_id != 5 &&
      loginUser.roles_id != 7 && loginUser.roles_id != 8 
    ) {
      navigate('/login');
      return false;
    } 

    let exportedData;
    let exportedColumns;

    if (user.roles_id < 3) {
      // Export data and columns for columns
      exportedData = rows.map((row) => {
        return {
          member_id: row.original.member_id || "N/A",
          name: row.original.name || "N/A",
          email: row.original.email || "N/A",
          PID: row.original.PID || "N/A",
          AID: row.original.AID || "N/A",
          plan: row.original.plan || "N/A",
          phone_no: formatPhoneNumber(row.original.phone_no) || "N/A",
          subscription_date: `${row.original.subscription_date ? Moment(row.original.subscription_date).format('MM-DD-YYYY H:mm') + " Hrs" : ''}`,
          close_date: `${row.original.close_date ? Moment(row.original.close_date).format('MM-DD-YYYY') : ''}`,
          lastupdated: `${row.original.activity_log ? Moment(row.original.activity_log.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY hh:mm:ss') : ''}`,
          next_follow_up: `${row.original.activity_log ? (row.original.activity_log.next_follow_up != null && row.original.activity_log.next_follow_up != '0000-00-00 00:00:00' ? Moment(row.original.activity_log.next_follow_up).format('MM-DD-YY hh:mm') : (row.original.activity_log.add_next_followup ? Moment(row.original.activity_log.add_next_followup).format('MM-DD-YY hh:mm') : 'N/A')) : 'N/A'}`,
          recentnote: `${row.original.activity_log ? row.original.activity_log.notes : 'N/A'}`,
          assignedTo: `${row.original.user ? row.original.user.first_name + " " + row.original.user.last_name : 'N/A'}`,
          status: "Closed/Pending",
        };
      });

      exportedColumns = columns;
    } else {
      // Export data and columns for columns2
      exportedData = rows.map((row) => {
        return {
          member_id: row.original.member_id || "N/A",
          name: row.original.name || "N/A",
          email: row.original.email || "N/A",
          PID: row.original.PID || "N/A",
          AID: row.original.AID || "N/A",
          plan: row.original.plan || "N/A",
          phone_no: formatPhoneNumber(row.original.phone_no) || "N/A",
          subscription_date: `${row.original.subscription_date ? Moment(row.original.subscription_date).format('MM-DD-YYYY H:mm') + " Hrs" : ''}`,
          close_date: `${row.original.close_date ? Moment(row.original.close_date).format('MM-DD-YYYY') : ''}`,
          status: "Closed/Pending",
        };
      });

      exportedColumns = columns2;
    }

    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      fileName: 'Pending panel',
      headers: exportedColumns.map((column) => column.header),
    };

    // Convert data to CSV format
    const csvContent = convertToCSV(exportedData, csvOptions);

    // Create a Blob object from the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    // Create a download link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = csvOptions.fileName + '.csv';

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the file download
    link.click();

    // Clean up the temporary URL and remove the link from the document
    URL.revokeObjectURL(link.href);
    link.remove();
  };

  const convertToCSV = (data, csvOptions) => {
    const headers = csvOptions.headers.join(',') + '\n';

    const rows = data.map((row) => {
      return Object.values(row).map((value) => {
        // Handle any necessary formatting or escaping of values
        // For simplicity, we assume all values are already properly formatted
        return value;
      }).join(',');
    }).join('\n');

    return headers + rows;
  };
  return (
    <>
      {/* {isLoading ? <Loader /> : <></>} */}
      <MaterialReactTable
        columns={user.roles_id < 3 ? columns : columns2}
        data={data}
        enableColumnFilterModes
        enableRowSelection
        enablePinning
        filterFns={{
          customSearchFilterFn: (row, id, filterValue) =>
          row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
        }} 
        globalFilterFn="customSearchFilterFn" //exact serach filter functionality
        onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
        state={{ columnVisibility, rowSelection, isLoading: isLoading }} //pass our managed row selection state to the table to use
        getRowId={(row) => row.member_id}
        initialState={{
          showColumnFilters: false,
          density: 'compact',
          columnPinning: { left: ['mrt-row-select','member_id'] }
        }}
        onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
        positionToolbarAlertBanner="bottom"
        enableDensityToggle={false}
        enableColumnFilters={config.DatatableColumnFilter}
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
          >
            <Button
              color="primary"
              disabled={table.getPrePaginationRowModel().rows.length === 0}
								//export all rows, including from the next page, (still respects filtering and sorting)
                  onClick={() =>
                    handleExportData(table.getPrePaginationRowModel().rows)
                  }
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              // onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              CSV
            </Button>
          </Box>
        )}
      />
      <>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={fullWidth}
          maxWidth={maxWidth}
        >
          <DialogTitle id="alert-dialog-title" className="back">
            <Typography variant='h5'>Re-Assign User</Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container item xs mt={2}>
              <FormControl fullWidth size='small'>
                <InputLabel id="demo-multiple-name-label">Select User</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  name='assigedUser'
                  id='assigedUser'
                  label="Select User"
                  value={assigedUser}
                  onChange={(e) => { setAssigedUser(e.target.value) }}
                  MenuProps={MenuProps}
                >
                  {users.map((row, index) => (
                    <MenuItem value={row.id} key={index}>{row.first_name + " " + row.last_name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button variant="contained" color="primary" sx={{ minWidth: 200 }} onClick={handleClose}>Close</Button>
            <Button variant="contained" color="success" sx={{ minWidth: 200 }} onClick={ChangeAssignedUser}>Save</Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
}


export default React.memo(Pending);