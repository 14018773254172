import React, { useMemo, useState, useEffect } from 'react';
import { Box, Table, TableCell, Alert, Grid, TableContainer, TableRow, Paper, Button, Tooltip, Typography ,Dialog,DialogContent,DialogActions} from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import Moment from 'moment';
import AuthUser from "../Auth/AuthUser";
import { useParams, useNavigate, Link } from 'react-router-dom';
import config from '../../AppConfig';
import AiFillPhone from '@mui/icons-material/Phone';
import CommonFliter from './CommonFliter';
import LinkIcon from '@mui/icons-material/Link';
import { useDispatch } from 'react-redux';
import { fetchData,postData } from '../../redux/apiSlice';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import formatPhoneNumber from '../../../src/Components/Formats/PhoneNumberValidation';
import {openOrFocusWindow } from  '../CommonFormula';
import swal from "sweetalert";

function createData(name, calories, fat, carbs, protein) {
	return { name, calories, fat, carbs, protein };
}

const rows = [
	createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
];

function Disabled(props) {
	const dispatch = useDispatch();
	const { tab } = useParams();
	const { http, user } = AuthUser();
	const [data, setData] = useState([]);
	const [rowSelection, setRowSelection] = useState({});
	const [statusMessage, setStatusMesage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [search, setSearch] = useState("");
	const [perPage, setPerPage] = useState(10);
	const [isLoading, setIsLoading] = useState(true);
	const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
	const [deleteId, setDeleteId] = useState('');
	const q = search == '' ? 'null' : search;

	const navigate = useNavigate();

	const [columnVisibility, setColumnVisibility] = React.useState({});

	const [refresh, doRefresh] = useState(0);

	useEffect(() => {
		if (Object.values(columnVisibility).length > 0) {
			setColumnVisibility(columnVisibility);
		}
		//do something when the row selection changes...
		console.info({ rowSelection });
	}, [rowSelection]);

	const restoreLead = async (id) => {
		const apiUrl = '/restore/' + id;
		const args = {};
		const res = await dispatch(postData({ apiUrl, args })).unwrap();
		if (res) {
			if (res.success === true) {
				navigate('/manageleads/hot-leads');
			}else{
				if(res.message == "This action is unauthorized.") {
					navigate('/login');
				}
			}
		}
	}

	const startLoading = () => {
		setIsLoading(true);
		props.freezStart();
	}

	const customSortFun = (rowA, rowB) => {
		
		const sortA = rowA.original.company.referred_by_company ? rowA.original.company.referred_by_company.company_name : rowA.original.company.referred_by != null ? rowA.original.company.referred_by : "";
		const sortB =rowB.original.company.referred_by_company ? rowB.original.company.referred_by_company.company_name : rowB.original.company.referred_by != null ? rowB.original.company.referred_by : "";
    	return sortA.localeCompare(sortB, undefined, { sensitivity: 'base' });
	};
	const deleteLog=async ()=>{
		setOpenConfirmationBox(false)
		setIsLoading(true)
		const apiUrl = '/manageLeads/delete';
		const args = {
			id: deleteId,
		}
		const res = await dispatch(postData({ apiUrl, args })).unwrap();
	  	if(res) 
		{
			doRefresh(prev => prev + 1);
			setIsLoading(false)
			if (res.success === true) {
				swal({
					title: "Success",
					text: res.message,
					icon: "success",
					button: "Ok",
				})
			} else {
			setIsLoading(false)
			swal({
				title: "error",
				text: res.message,
				icon: "error",
				button: "Ok",
			})
			}
		}
	}
	const columns = useMemo(
		//column definitions...
		() => [
			{
				accessorFn: (data) => `${data.first_name.trim() + ' ' + data.last_name.trim()}`,
				id: 'name',
				header: 'Name',
				size: 50,
				enablePinning: true,
			},
			{
				accessorFn: (data) => `${data.email ? data.email.trim() : ''} `,  
				id: 'email',
				header: 'Email ID',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<Typography>{row.original.email ? row.original.email.trim() : 'N/A'}</Typography>
				),
			},
			{
				accessorKey: 'phone_no',
				accessorFn: (row) => `${row.phone_no ? formatPhoneNumber(row.phone_no.trim()) : 'N/A'}`,
				filterVariant: 'range',
				id: 'phoneNo',
				header: 'Phone no.',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					row.original.phone_no ?
						<div>
							{formatPhoneNumber(row.original.phone_no.trim())}
							&nbsp;&nbsp;
							<span>
								{row.original.current_hours != '' ?
									row.original.current_hours >= 8 && row.original.current_hours <= 19 ?
										<Tooltip title={row.original.current_time}><WbSunnyIcon color="warning"></WbSunnyIcon ></Tooltip> : <Tooltip title={row.original.current_time}><DarkModeIcon ></DarkModeIcon></Tooltip>
									: ''
								}
							</span>
							{user.roles_id == 13?null:
							<Tooltip title="Dial" arrow>
								<a onClick={(event) => {event.preventDefault(); openOrFocusWindow(config.CallHippoDialer+row.original.phone_no); }} >
									<AiFillPhone color="primary" />
								</a>
							</Tooltip>
						}
						</div> : 'N/A'
				),
			},
			{
				accessorFn: (data) => `${data.pandadoc_id ? data.pandadoc_id : ''}`,
				filterVariant: 'range',
				id: 'docId',
				header: 'Agreement ID',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (row.original.pandadoc_id ? row.original.pandadoc_id : 'N/A'),
				//custom conditional format and styling
			},
			{
				accessorFn: (data) => `${(data.company) ? (data.company.aid_master) ? data.company.aid_master.aid.trim() : '' : ''}`,
				id: 'aid',
				header: 'AID',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (row.original.company ? (row.original.company.aid_master) ? row.original.company.aid_master.aid.trim() : 'N/A' : 'N/A'),
				//custom conditional format and styling
			},
			{
				accessorFn: (data) => `${data.status ? data.status.status_name.trim() : ''}`,
				filterVariant: 'range',
				id: 'status',
				header: 'Status',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (row.original.status ? row.original.status.status_name.trim() : 'N/A'),
				//custom conditional format and styling
			},
			{
				accessorFn: (data) => `${data.updated_at ? data.updated_at : ''}`,
				filterVariant: 'range',
				id: 'lastUpdated',
				header: 'Last Updated',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<div>
						{row.original.updated_at ? Moment(row.original.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A'}
					</div>
				),
				//custom conditional format and styling
			},
			{
				accessorFn: (data) => `${data.created_at ? data.created_at : ''}`,
				filterVariant: 'range',
				id: 'leadGeneratedDate',
				header: 'Lead Generated Date',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<div>
						{row.original.created_at ? Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : "N/A"}
					</div>
				),
				//custom conditional format and styling
			},
			{
				accessorFn: (data) => `${data.company ? data.company.company_name : 'N/A'}`,
				id: 'company_name',
				header: 'Company Name',
				size: 50,
			},
			{
				accessorFn: (data) => `${data.last_activity_logs ? data.last_activity_logs.type ? data.last_activity_logs.type.trim() : '' : ''}`,
				filterVariant: 'range',
				id: 'followup',
				header: 'Followup',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<>
						{row.original.last_activity_logs ? row.original.last_activity_logs.type ? row.original.last_activity_logs.type.trim() : "N/A" : "N/A"}
					</>
				),
				//custom conditional format and styling
			},
			{
				accessorFn: (data) => `${data.company.referred_by_company ? data.company.referred_by_company.company_name : data.company.referred_by != null ? data.company.referred_by : ""}`,
				filterVariant: 'range',
				id: 'leadType',
				header: 'Lead Type',
				size: 50,
				sortingFn: customSortFun,
				Cell: ({ renderedCellValue, row }) => (row.original.company.referred_by_company ? row.original.company.referred_by_company.company_name : row.original.company.referred_by != null ? row.original.company.referred_by : "N/A"),
				//custom conditional format and styling
			},
			{
				accessorFn: (data) => `${data.company
					? data.company.marketing_type !== null
						? data.company.marketing_type
						: ''
					: ''}`,
				filterVariant: 'range',
				id: 'softwareUsed',
				header: 'Software Used',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<div>
						{row.original.company ? (row.original.company.marketing_type !== null ? row.original.company.marketing_type : 'N/A') : 'N/A'}
					</div>
				),
				
				//custom conditional format and styling
			},
			{
				accessorFn: (data) => `${data.last_activity_logs ? data.last_activity_logs.notes ? data.last_activity_logs.notes.trim() : '' : ''}`,
				filterVariant: 'range',
				id: 'comment',
				header: 'Comment',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<Box className="datatable">
						<Typography>{row.original.last_activity_logs ? row.original.last_activity_logs.notes ? row.original.last_activity_logs.notes.trim() : 'N/A' : 'N/A'}</Typography>
					</Box>
				),
			},
			{
				accessorFn: (data) => `${data.last_activity_logs ? data.last_activity_logs.add_next_followup ? data.last_activity_logs.add_next_followup : '' : ''}`,
				filterVariant: 'range',
				id: 'nextFollowup',
				header: 'Next Followup',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<div>
						{row.original.last_activity_logs && row.original.last_activity_logs.add_next_followup !== "0000-00-00 00:00:00" && row.original.last_activity_logs.add_next_followup != null
							? Moment(row.original.last_activity_logs.add_next_followup).format('MM-DD-YYYY H:mm')
							: "N/A"
						}
					</div>
				),
				//custom conditional format and styling
			},
			{
				accessorFn: (data) => `${data.lead_assigned_user ? data.lead_assigned_user.name.trim() : ''}`,
				filterVariant: 'range',
				id: 'sssignedTo',
				header: 'Assigned To',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<div>
						{row.original.lead_assigned_user ? row.original.lead_assigned_user.name.trim() : 'N/A'
						}
					</div>
				),
			},
			{
				accessorFn: (data) => <>
				{user.roles_id=== 13 ?
                ( <Tooltip title="Delete" arrow >
                  <img src={require('../../assets/icons/deleteIcon2.png')}  onClick={()=>{setDeleteId(data.company_master_id);setOpenConfirmationBox(true)}}/>
                  </Tooltip>):
					user.roles_id == 6 ? null :
						<Tooltip title="Reactivate" arrow><img src={require('../../assets/images/restore.png')} onClick={() => { restoreLead(data.id) }} /></Tooltip>
					}
				</>,
				id: 'actions',
				header: 'Actions',
				enableSorting: false
			}
		]
	)

	const changeRowSelection = () => {
		setRowSelection([]);
	};

	const handleReferredList = (data) => {
		setData(data);
		setIsLoading(false);
		props.freezStop();
	}
	const handleTablePreferences = (data) => {
		if (Object.values(data).length > 0) {
			setColumnVisibility(data);
		}
		props.freezStop();
	}

	const getPandaDoc = async (name) => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  > 2) {
			navigate('/login');
			return false;
		}
		const apiUrl = `/get-pdf/${name}`;
		window.open(config.API_BASE_URL+apiUrl); 
        // const res = await dispatch(fetchData(apiUrl)).unwrap();
        // if(res)
		// {	
		// 	if (res.success) {
		// 		window.open(config.BACKEND_URL+res.data.pdfUrl); 
		// 	}else {
		// 		swal({
		// 			title: "error",
		// 			text: res.message,
		// 			icon: "error",
		// 			button: "Ok",
		// 		})
		// 	}
		// }
	}

	//csv option start here
	const csvOptions = {
		fieldSeparator: ',',
		quoteStrings: '"',
		decimalSeparator: '.',
		showLabels: true,
		useBom: true,
		useKeysAsHeaders: false,
		fileName: 'Disabled Leads',
		headers: ['Name', 'Email ID', 'Phone No.', 'Agreement ID', 'AID', 'status', 'Last Updated', 'Lead Generated Date','Company Name', 'Followup', 'Lead Type', 'Software Used', 'Comment', 'Next Followup', 'Assigned To'],
	};

	const csvExporter = new ExportToCsv(csvOptions);
	const handleExportRows = (rows) => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  > 2 && user.roles_id != 13) {
			navigate('/login');
			return false;
		}
		const exportedData = rows.map((row) => {
			return {
				name: `${row.original.first_name} ${row.original.last_name}` || "N/A",
				email_id: `${row.original.email}` || "N/A",
				phone: `${formatPhoneNumber(row.original.phone_no)}` || "N/A",
				doc_id: `${row.original.pandadoc_id ? row.original.pandadoc_id:"N/A"}` || "N/A",
				aid: `${(row.original.company) ? (row.original.company.aid_master) ? row.original.company.aid_master.aid : 'N/A' : 'N/A'}`,
				status: `${row.original.status ? row.original.status.status_name : 'N/A'}`,
				last_updated: `${Moment(row.original.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm')}` || "N/A",
				lead_generated_date: `${Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm')}` || "N/A",
				company_name: `${row.original.company ? row.original.company.company_name : 'N/A'}`,
				followup: `${row.original.last_activity_logs ? row.original.last_activity_logs.type ? row.original.last_activity_logs.type : 'N/A' : 'N/A'}`,
				lead_type: `${row.original.company.referred_by_company ? row.original.company.referred_by_company.company_name : row.original.company.referred_by != null ? row.original.company.referred_by : "N/A"}`,
				software_used: row.original.company?.marketing_type || "N/A",
				comment: `${row.original.last_activity_logs ? row.original.last_activity_logs.notes.replace(/[\r\n]+/g, '') : 'N/A'}`,
				add_next_followup: `${row.original.last_activity_logs ? row.original.last_activity_logs.add_next_followup ? Moment(row.original.last_activity_logs.add_next_followup).format('MM-DD-YYYY H:mm') : 'N/A' : 'N/A'}`,
				assigned_to: `${row.original.lead_assigned_user ? row.original.lead_assigned_user.name : 'N/A'}`,
			};
		});
		// csvExporter.generateCsv(exportedData);
		// Convert data to CSV format
		const csvContent = convertToCSV(exportedData);

		// Create a Blob object from the CSV data
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

		// Create a download link element
		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		link.download = csvOptions.fileName + '.csv';

		// Append the link to the document body
		document.body.appendChild(link);

		// Programmatically click the link to trigger the file download
		link.click();

		// Clean up the temporary URL and remove the link from the document
		URL.revokeObjectURL(link.href);
		link.remove();
	};

	const convertToCSV = (data) => {
		const headers = csvOptions.headers.join(',') + '\n';

		const rows = data.map((row) => {
			return Object.values(row).map((value) => {
				// Handle any necessary formatting or escaping of values
				// For simplicity, we assume all values are already properly formatted

				// Wrap the value with double quotes if it contains a comma
				if (typeof value === 'string' && value.includes(',')) {
					return `"${value}"`;
				}

				return value;
			}).join(',');
		}).join('\n');

		return headers + rows;
	};
	//csv end here
	return (
		<>
			<Box sx={{ width: '100%' }}>
				{statusMessage ? <Alert severity="success">{statusMessage}</Alert> : ''}
				{errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
			</Box>
			<CommonFliter http={http} leadType={tab} perPage={perPage}
				rowSelection={rowSelection}
				onDataChange={handleReferredList}
				onTablePreferences={handleTablePreferences}
				refresh={refresh}
				userId={user.id}
				columnArray={columnVisibility}
				changeRowSelection={changeRowSelection}
				startLoading={startLoading}
			/>
			<Grid mt={2}>
				<MaterialReactTable
					columns={columns}
					data={data}
					filterFns={{
						customSearchFilterFn: (row, id, filterValue) =>
						row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
					}}                                   
					globalFilterFn="customSearchFilterFn" //exact serach filter functionality
					enableColumnFilterModes
					enablePinning
					enableRowSelection={user.roles_id != 13}
					enableColumnFilters={config.DatatableColumnFilter}
					getRowId={(row) => row.id} //give each row a more useful id
					onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
					state={{ columnVisibility, rowSelection, isLoading: isLoading }} //pass our managed row selection state to the table to use
					initialState={{ showColumnFilters: false, density: 'compact',
						columnPinning: { left: ['mrt-row-expand','mrt-row-select', 'name'] }
					 }}
					onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
					positionToolbarAlertBanner="bottom"
					enableDensityToggle={false}
					muiTableHeadCellProps={{
						//simple styling with the `sx` prop, works just like a style prop in this example
						sx: {
							backgroundColor: "#F6FAFF",
						},
					}}
					renderDetailPanel={({ row }) => (
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-around',
								alignItems: 'center',
							}}
						>
							<TableContainer component={Paper}>
								<Table className='back'>
									<TableRow>
										<TableCell style={{ fontWeight: 'bold' }}>Name:</TableCell>
										<TableCell>{row.original.first_name + ' ' + row.original.last_name}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Email:</TableCell>
										<TableCell>{row.original.email}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Phone No:</TableCell>
										<TableCell>{formatPhoneNumber(row.original.phone_no)}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell style={{ fontWeight: 'bold' }}>AID:</TableCell>
										<TableCell>{row.original.company ? row.original.company.aid_master ? row.original.company.aid_master.aid : 'N/A' : 'N/A'}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Agreement ID:</TableCell>
										<TableCell>{row.original.pandadoc_id}
											{user.roles_id == 6 || user.roles_id == 13 ? null :
											<Tooltip title="View Agreement" arrow><LinkIcon color="primary" onClick={() => {
												{getPandaDoc(row.original.company.company_name) }
											}} /></Tooltip>}
										</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Status:</TableCell>
										<TableCell>{row.original.status ? row.original.status.status_name : ''}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell style={{ fontWeight: 'bold' }}>Last Updated:</TableCell>
										<TableCell>{Moment(row.original.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm')}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Lead Generated Date:</TableCell>
										<TableCell>{Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm')}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Followup:</TableCell>
										<TableCell>{(row.original.last_activity_logs ? (row.original.last_activity_logs.type ? row.original.last_activity_logs.type : 'N/A') : 'N/A')}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell style={{ fontWeight: 'bold' }}>Lead Type:</TableCell>
										<TableCell>{row.original.company.referred_by_company ? row.original.company.referred_by_company.company_name : row.original.company.referred_by != null ? row.original.company.referred_by : "N/A"}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Software Used:</TableCell>
										<TableCell>{row.original.company ? row.original.company.marketing_type : 'N/A'}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Comments:</TableCell>
										<TableCell>{row.original.last_activity_logs ? row.original.last_activity_logs.notes ? row.original.last_activity_logs.notes : 'N/A' : 'N/A'}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell style={{ fontWeight: 'bold' }}>Next Followup:</TableCell>
										<TableCell>{row.original.last_activity_logs ? row.original.last_activity_logs.add_next_followup ? row.original.last_activity_logs.add_next_followup : 'N/A' : 'N/A'}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Assigned To:</TableCell>
										<TableCell>{row.original.lead_assigned_user ? row.original.lead_assigned_user.name : 'N/A'}</TableCell>
									</TableRow>
								</Table>
							</TableContainer>
						</Box>
					)}
					//top csv option
					renderTopToolbarCustomActions={({ table }) => (
						<Box
							sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
						>
							<Button
								sx={{ bgColor: "#2A68B3" }}
								//export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
								disabled={table.getPrePaginationRowModel().rows.length === 0}
								//export all rows, including from the next page, (still respects filtering and sorting)
									onClick={() =>
										handleExportRows(table.getPrePaginationRowModel().rows)
									}
								variant="contained"
								className='export'
							>
								CSV
							</Button>
						</Box>
					)
					}
				/>
			</Grid>
			<Dialog
                                fullWidth
                                maxWidth={'sm'}
                                open={openConfirmationBox}
                                onClose={(_, reason) => {
                                    if (reason !== "backdropClick") {
                                        setOpenConfirmationBox(false);
                                    }
                                }}
                            >
                 
                                <DialogContent>
                                    <Box mt={1} sx={{textAlign:'center'}}>
                                        <Typography variant='h5'>Are you sure you want to delete this lead?</Typography>
                                    </Box>
                                </DialogContent>
                                <DialogActions sx={{ margin: 'auto' }} >
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" onClick={()=>{deleteLog()}} color="success" sx={{ minWidth: 250 }} >
                                            Delete
                                        </Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" onClick={() => setOpenConfirmationBox(false)} color="primary" sx={{ minWidth: 250 }}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                </DialogActions>
                    </Dialog>
		</>
	);

}


export default Disabled;