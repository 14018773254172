import React, { useMemo, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import { Alert, OutlinedInput, ListItemIcon, Checkbox, ListItemText, Grid, Button, Dialog, DialogActions, FormControl, Box, InputLabel, Select, MenuItem, DialogContent, DialogTitle, TextField, Typography, Tooltip } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Moment from 'moment';
import Loader from '../Loader/Loader';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Formik } from 'formik';
import * as Yup from 'yup';
import AuthUser from '../Auth/AuthUser';
import { useLocation } from 'react-router-dom';
import { MenuProps, useStyles } from "./utils";
import config from '../../AppConfig';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import swal from "sweetalert";
import formatPhoneNumber from '../../../src/Components/Formats/PhoneNumberValidation';
import AiFillPhone from '@mui/icons-material/Phone';
import {openOrFocusWindow } from  '../CommonFormula';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../redux/apiSlice';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import DarkModeIcon from '@mui/icons-material/DarkMode';

const RequestFormInitialValues = {
  note: '',
  callType: 'Suspended Member List',
  disposition: '',
  reason: '',
  callSource:'',
};
function SuspendedMembersList(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('md');
  const [data, setData] = useState(props.data);
  const [open1, setOpen1] = React.useState(false);
  const [opensuspended, setOpenSuspended] = React.useState(false);

  const [loader, setLoader] = useState('');
  // const { id } = useParams();
  const [MemberActivityList, setMemberActivityList] = useState([]);
  const [dispositionListData, setdispositionList] = useState([]);
  const [ReasonList, setReasonList] = useState([]);
  const [adminList, setAdminList] = React.useState([]);

  const [assignedTo, setAssignedTo] = useState((JSON.parse(localStorage.getItem('user'))).roles_id===2?(JSON.parse(localStorage.getItem('user'))).id:'unAssigned');
  const [assignedOpen, setAssignedOpen] = useState(false);
  const [assignedToAction, setAssignedToAction] = useState('Action');
  const [rowSelection, setRowSelection] = useState({});
  const [statusMessage, setStatusMesage] = useState();
  const [disableActivityButton, setDisableActivityButton] = useState(false);
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const companyId = queryParams.get('companyId');
  const rowsSeleted = Object.keys(rowSelection);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [scrollX, setScrollX] = useState(0);
  const [submitEnable, setSubmitEnable] = useState(false);
  const [contactTypeList, setCallTypeList] = useState([]);
  const tablePreferencesArray = {

  }

  const fetchReport =async () => {
    setErrorMessage("")
    setIsLoading(true)

    const apiUrl = '/suspendedMemberList';
    const args = {
      assignto: assignedTo,
      companyId: companyId,
    }
    const res = await dispatch(postData({apiUrl,args})).unwrap();
    if(res)
    {
      setData(res.data)
      setIsLoading(false)
    }else
    {
      setErrorMessage(" went wrong.")
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchReport();
  }, [assignedTo]);

  const fetchAdminList = () => {
    http.get('/fetchOnlyAdmins')
      .then((res) => {
        // console.log(res.data)
        setAdminList(res.data);
        //  const elementsList = res.data.map(row => row.name); // Fetches the element at column 2 from each row

        // Create a 2D array from the list
        // const twoDArray = elementsList.map(element => [element]); // Creates a new row for each element
        // setAdminList(twoDArray);
      });
    // const user = JSON.parse(localStorage.getItem('user'));
    // if (user.roles_id === 2) {
    //   alert(user.id)
    //   setAssignedTo(user.id)
      
    // } else {
    //   setAssignedTo('all')
    // }
  };

  useEffect(() => {
    fetchAdminList();
    fetchCallTypeList();
  }, []);

  const fetchCallTypeList = async () => {
    const apiUrl = '/fetchContactLogCallType'; // Replace with your API endpoint
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      setCallTypeList(res);
    }
  }

  const navigate = useNavigate();

  const handleClickOpen1 = (member_id, name) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if (loginUser.roles_id >= 3) {
      navigate('/login');
      return false;
    }
    RequestFormInitialValues.member_id = member_id;
    RequestFormInitialValues.memberName = name;
    susMemberActivityList(member_id)
    dispositionList();
    setOpenSuspended(true);



  };
  const susMemberActivityList = ((member_id) => {
    http.get('suspendedMemberActivityData/' + member_id)
      .then((res) => {
        setMemberActivityList(res.data);
        setLoader('');
      })


  });
  const dispositionList = (() => {
    http.get('getDisposition')
      .then((res) => {
        console.log(res.data)
        setdispositionList(res.data);
        setLoader('');
      })

  });


  const handleClose1 = () => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if (loginUser.roles_id >= 3) {
      navigate('/login');
      return false;
    }
    setOpenSuspended(false);
  };
  const handleCloseAssigned = () => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if (loginUser.roles_id >= 3) {
      navigate('/login');
      return false;
    } else {
      setAssignedOpen(false);
      setAssignedToAction('Action');
    }

  };

  ////////////////////////
  useEffect(() => {
    if (Object.values(columnVisibility).length > 0) {
      setColumnVisibility(columnVisibility);
    }
    //do something when the row selection changes...
    // console.info({ rowSelection });
  }, []);

  useEffect(() => {
    tablePreferences();
  }, []);
  ////////////////////////

  useEffect(() => {
    if (Object.values(columnVisibility).length > 0) {
      http.post('/saveTablePreferences', {
        columnArray: columnVisibility,
        userId: user.id,
        tableId: 'suspendedmember'
      }).then((result) => {
      })
    }
  }, [columnVisibility])
  const tablePreferences = () => {
    http.get('/fetchTablePreferences/suspendedmember')
      .then((res) => {
        if (Object.values(res.data).length == 0) {
          setColumnVisibility(tablePreferencesArray);
        } else {
          setColumnVisibility((JSON.parse(res.data.preferences)));
        }
      });
  }

  //select dropdown
  const [age, setAge] = React.useState('');



  const handleChange = (event) => {
    fetchReport();
    setAge(event.target.value);
    setDisableActivityButton(false);
  };



  const columns = useMemo(
    //column definitions...
    () => [
      {
        accessorFn: (data) => `${data.member_id ? data.member_id : ''}`,
        id: 'Member Id',
        header: 'Member Id',
        size: 50,
        enablePinning: true,
        Cell: ({ renderedCellValue, row }) => (row.original.member_id ? row.original.member_id : "N/A"),
      },
      {
        accessorFn: (data) => `${data.name ? data.name.trim() : ''}`,
        id: 'Name',
        header: 'Name',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.name ? row.original.name.trim() : "N/A"),
      },
      {
        accessorFn: (data) => `${data.email ? data.email.trim() : ''}`,
        id: 'Email',
        header: 'Email',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.email ? row.original.email.trim() : 'N/A'),
      },
      {
        accessorFn: (data) => `${data.phone_number ? data.phone_number : ''}`,
        id: 'PhoneNumber',
        header: 'Phone Number',
        size: 50,
        Cell: ({ renderedCellValue,row }) => (
					row.original.phone_number ?
					<div>
						{formatPhoneNumber(row.original.phone_number)}
						&nbsp;&nbsp;
              <span>
                  { row.original.current_hours != '' ? 
                          row.original.current_hours >= 8 && row.original.current_hours <= 19 ?
                          <Tooltip title={row.original.current_time}><WbSunnyIcon color="warning"></WbSunnyIcon ></Tooltip> : <Tooltip title={row.original.current_time}><DarkModeIcon ></DarkModeIcon></Tooltip>
                      :''
                  }
              </span>
              {user.roles_id == 13?null:<Tooltip title="Dial" arrow>
                      <a onClick={(event) => {event.preventDefault(); openOrFocusWindow(config.CallHippoDialer+row.original.phone_number); }} >
                          <AiFillPhone color="primary" />
                      </a>
                  </Tooltip>}
					</div>: 'N/A'
				),
      },
      {
        accessorFn: (data) => `${data.publisher_id ? data.publisher_id : ''}`,
        id: 'PID',
        header: 'PID',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.publisher_id ? row.original.publisher_id : 'N/A'),
      },
      {
        accessorFn: (data) => `${data.aid ? data.aid.trim() : ''}`,
        id: 'AID',
        header: 'AID',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.aid ? row.original.aid.trim() : 'N/A'),
      },

      {
        accessorFn: (data) => `${data.date_range_filter ? data.date_range_filter : ''}`,
        id: 'Subscription Date',
        header: 'Subscription Date',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <div style={{ textAlign: "center" }}>
            {row.original.date_range_filter ? Moment(row.original.date_range_filter).format('MM-DD-YYYY') : "N/A"}
          </div>
        ),
      },

      {
        accessorFn: (data) => `${data.agentName ? data.agentName.trim() : ''}`,
        id: 'Agent',
        header: 'Agent',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.agentName ? row.original.agentName.trim() : 'N/A'),
      },
      {
        accessorFn: (data) => `${data.updated_at ? data.updated_at : ''}`,
        id: 'Action Date',
        header: 'Action Date',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <div style={{ textAlign: "center" }}>
            {row.original.updated_at ? Moment(row.original.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY') : "N/A"}
          </div>
        ),
      },
      {
        accessorFn: (data) => <>
          <Button onClick={() => { handleClickOpen1(data.member_id, data.name) }}><Tooltip title="Action" arrow><img src={require('../../assets/images/reactivate.png')} /></Tooltip></Button>
        </>,
        id: 'actions',
        header: 'Actions',
        enableSorting: false,
      },
    ]
  );
  const columns3 = useMemo(
    //column definitions...
    () => [
      {
        accessorFn: (data) => `${data.member_id ? data.member_id : ''}`,
        id: 'Member Id',
        header: 'Member Id',
        size: 50,
        enablePinning: true,
        Cell: ({ renderedCellValue, row }) => (row.original.member_id ? row.original.member_id : "N/A"),
      },
      {
        accessorFn: (data) => `${data.name ? data.name.trim() : ''}`,
        id: 'Name',
        header: 'Name',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.name ? row.original.name.trim() : "N/A"),
      },
      {
        accessorFn: (data) => `${data.email ? data.email.trim() : ''}`,
        id: 'Email',
        header: 'Email',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.email ? row.original.email.trim() : "N/A"),
      },
      {
        accessorFn: (data) => `${data.phone_number ? data.phone_number : ''}`,
        id: 'PhoneNumber',
        header: 'Phone Number',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.phone_number  ? formatPhoneNumber(row.original.phone_number): 'N/A'),
      },
      {
        accessorFn: (data) => `${data.publisher_id ? data.publisher_id : ''}`,
        id: 'PID',
        header: 'PID',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.publisher_id ? row.original.publisher_id : "N/A"),
      },
      {
        accessorFn: (data) => `${data.aid ? data.aid.trim() : ''}`,
        id: 'AID',
        header: 'AID',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.aid ? row.original.aid.trim() : "N/A"),
      },
      {
        accessorFn: (data) => `${data.date_range_filter ? data.date_range_filter : ''}`,
        id: 'Subscription Date',
        header: 'Subscription Date',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <div style={{ textAlign: "center" }}>
            {row.original.date_range_filter ? Moment(row.original.date_range_filter).format('MM-DD-YYYY') : "N/A"}
          </div>
        ),
      },
      {
        accessorFn: (data) => `${data.agentName ? data.agentName.trim() : ''}`,
        id: 'Agent',
        header: 'Agent',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.agentName ? row.original.agentName.trim() : "N/A"),
      },
      {


        accessorFn: (data) => `${data.updated_at ? data.updated_at : ''}`,
        id: 'Action Date',
        header: 'Action Date',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <div style={{ textAlign: "center" }}>
            {row.original.updated_at ? Moment(row.original.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY') : "N/A"}
          </div>
        ),
      },

    ]
  );
  const [open, setOpen] = useState(false);


  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value,
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };
  const handleClickOpen = (id, email) => {
    RequestFormInitialValues.id = id;
    RequestFormInitialValues.email = email;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenAssigned = () => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if (loginUser.roles_id >= 3) {
      navigate('/login');
      return false;
    } else {
      setAssignedOpen(true);
    }

  };

  const handleActionChange =async (event) => {
    if (rowsSeleted.length > 0) {
      setAssignedToAction(event.target.value);
      if (event.target.value === "assignToStaff") {
        handleClickOpenAssigned();
      } else if (event.target.value === "assignToNone") {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if (loginUser.roles_id >= 3) {
          navigate('/login');
          return false;
        }
        setIsLoading(true);
        
        const apiUrl = '/assignToNoneMembers';
        const args = {
          memberId: rowsSeleted,
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res)
        {
          swal({
            title: "Success",
            text: res.message,
            icon: "success",
            button: "Ok",
          })

          fetchReport();
          setSelectedID([]);
          setSelected([]);
          setRowSelection([]);
          setAssignedToAction('Action');
          setIsLoading(false);
        }
      }
    } else {
      alert('Please select at least one lead');
    }
  }

  const { http, user } = AuthUser();
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [user1, setUser1] = useState(user);

  //Start Request Add Missing Member
  //Schema for formik form
  const RequestFormSchema = Yup.object().shape({
    callSource: Yup.string().required('Contact Type required'),
    disposition: Yup.string().required('Please enter disposition'),
    reason: Yup.string().required('Please enter reason'),


  })
  const assignedToStaffSchema = Yup.object().shape({
    staffId: Yup.array()
      .required('Please select User to Assign.'),

  });
  //Submit Request Add Missing Member Form
  const submitRequest = async (values, { resetForm }) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if (loginUser.roles_id >= 3) {
      navigate('/login');
      return false;
    }
    setOpenSuspended(false)
    setErrorMessage("")
    setIsLoading(true)
    setDisable(true);
    setDisableActivityButton(true);
    RequestFormInitialValues.member_id = values.member_id;
    RequestFormInitialValues.note = values.note;
    RequestFormInitialValues.callType = values.callType;
    RequestFormInitialValues.disposition = values.disposition;
    RequestFormInitialValues.reason = values.reason;
    RequestFormInitialValues.callSource = values.callSource;

    const apiUrl = '/createSuspendedMemberActivity';
    const args = {
      member_id: values.member_id,
      note: values.note,
      call_type: values.callType,
      disposition: values.disposition,
      reason: values.reason,
      callSource: values.callSource,
    }
    const res = await dispatch(postData({apiUrl,args})).unwrap();
    if(res)
    {
      if (res.success === false) {
        setErrorMessage(res.message)
        setIsLoading(false)
        setTimeout(function () {
          setErrorMessage('')
          setDisable(false)
          handleClose1();
          RequestFormInitialValues.note = '';
          RequestFormInitialValues.disposition = '';
          RequestFormInitialValues.reason = '';
          RequestFormInitialValues.callSource = '';


        }, 5000);
        setDisable(false);
      } else {
        setIsLoading(false)
        swal({
          title: "Success",
          text: res.message,
          icon: "success",
          button: "Ok",
        })
        // localStorage.setItem('success',res.data.message) 
        navigate('/suspendedMemberList');
        // setSuccessMessage(res.data.message)

        setTimeout(function () {
          setSuccessMessage('')
          setDisable(false)
          handleClose1();
          fetchReport();
        }, 5000);
        setDisable(false);
        RequestFormInitialValues.note = '';
        RequestFormInitialValues.disposition = '';
        RequestFormInitialValues.reason = '';
        RequestFormInitialValues.callSource ='';

      }
    }
    else
    {
      setErrorMessage("Something went wrong.")
      setIsLoading(false)
      setTimeout(function () {
        setErrorMessage('')
        setDisable(false)
        handleClose1();
      }, 5000);
      setDisable(false);
    }
    resetForm();
  }

  //action popup datatable start here
  const columns1 = useMemo(
    //column definitions...
    () => [
      {
        accessorFn: (MemberActivityList) => `${MemberActivityList.notes ? MemberActivityList.notes.trim() : ''}`,
        id: 'Notes',
        header: 'Notes',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (<Box className="notes">
          <Typography> {row.original.notes ? row.original.notes.trim() : 'N/A'} </Typography>
        </Box>),
      },
      {
        accessorFn: (MemberActivityList) => `${MemberActivityList.call_type ? MemberActivityList.call_type : ''}`,
        enableClickToCopy: true,
        id: 'Call Type',
        header: 'Call Type',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.call_type ? row.original.call_type : 'N/A'),
      },
      {
        accessorFn: (MemberActivityList) => `${MemberActivityList.disposition ? MemberActivityList.disposition.trim() : ''}`,
        id: 'Disposition',
        header: 'Disposition',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.disposition ? row.original.disposition.trim() : 'N/A'),
        //custom conditional format and styling
      },
      {
        accessorFn: (MemberActivityList) => `${MemberActivityList.reason ? MemberActivityList.reason.trim() : ''}`,
        id: 'Reason',
        header: 'Reason',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.reason ? row.original.reason.trim() : 'N/A'),
        //custom conditional format and styling
      },
      {
        accessorFn: (MemberActivityList) => `${MemberActivityList.name ? MemberActivityList.name.trim() : ''}`,
        id: 'Agent Name',
        header: 'Agent Name',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.name ? row.original.name.trim() : "N/A"),
        //custom conditional format and styling  
      },
      {
        accessorFn: (MemberActivityList) => `${MemberActivityList.created_at ? MemberActivityList.created_at : ''}`,
        id: 'Date',
        header: 'Date',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.created_at ? Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YY hh:mm:ss') + 'Hrs' : 'N/A'),
        // Cell:({renderedCellValue,row}) => (
        //   <div>
        //   {row.original.MemberActivityList.created_at ? Moment(row.original.MemberActivityList.created_at).tz('America/Los_Angeles').format('MM-DD-YY hh:mm:ss')+'Hrs' :"N/A"}
        //   </div>          
        //   ),
      },

    ]
  )
  const handledisposition = (event) => {
    http.get('getReason/' + event)
      .then((res) => {
        setReasonList(res.data);
        setLoader('');
      })


  };

  const handleSelectChange = (event) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if (loginUser.roles_id < 3) {
      const selectedValue = event.target.value; // Get the selected value from event
      setAssignedTo(selectedValue); // Set the selected value to state  
    } else {
      navigate('/login');
      return false;
    }

  }
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [selectedID, setSelectedID] = useState([]);
  const isAllSelected =
    adminList.length > 0 && selected.length === adminList.length;

  const handleChange2 = async (event, obj) => {

    const {
      target: { value }
    } = event;

    const extractedArray = adminList.map(arr => arr.id);
    setSelectedID(value);

    if (value[value.length - 1] === "all" || value[0] === "all") {
      setSelected(selected.length === adminList.length ? [] : extractedArray);
      setSelectedID(extractedArray);
      return;
    }
    setSelected(value);



  };
  const handleExportData = (rows) => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if (loginUser.roles_id > 5) {
      navigate('/login');
      return false;
    } 
    let exportedData;
    let exportedColumns;

    if (companyId === null) {
      // Export data and columns for columns
      exportedData = rows.map((row) => {
        return {
          'Member Id': `${row.original.member_id ? row.original.member_id : 'N/A'}`,
          'Name': `${row.original.name ? row.original.name : 'N/A'}`,
          'Email': `${row.original.email ? row.original.email : 'N/A'}`,
          'Phone Number': `${row.original.phone_number ? row.original.phone_number : 'N/A'}`,
          'PID': `${row.original.publisher_id ? row.original.publisher_id : 'N/A'}`,
          'AID': `${row.original.aid ? row.original.aid : 'N/A'}`,
          'Subscription Date': `${row.original.date_range_filter ? Moment(row.original.date_range_filter).format('MM-DD-YYYY') : 'N/A'}`,
          'Agent': `${row.original.agentName ? row.original.agentName : 'N/A'}`,
          'Action Date': `${row.original.updated_at ? Moment(row.original.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : 'N/A'}`,
        };
      });

      exportedColumns = columns.filter((column) => column.id !== 'actions');
    } else {
      // Export data and columns for columns3
      exportedData = rows.map((row) => {
        return {
          'Member Id': `${row.original.member_id ? row.original.member_id : 'N/A'}`,
          'Name': `${row.original.name ? row.original.name : 'N/A'}`,
          'Email': `${row.original.email ? row.original.email : 'N/A'}`,
          'Phone Number': `${row.original.phone_number ? row.original.phone_number : 'N/A'}`,
          'PID': `${row.original.publisher_id ? row.original.publisher_id : 'N/A'}`,
          'AID': `${row.original.aid ? row.original.aid : 'N/A'}`,
          'Subscription Date': `${row.original.date_range_filter ? Moment(row.original.date_range_filter).format('MM-DD-YYYY') : 'N/A'}`,
          'Agent': `${row.original.agentName ? row.original.agentName : 'N/A'}`,
          'Action Date': `${row.original.updated_at ? Moment(row.original.updated_at).format('MM-DD-YYYY H:mm') : 'N/A'}`,
        };
      });

      exportedColumns = columns3.filter((column) => column.id !== 'actions');
    }

    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      fileName: 'Suspended Member',
      headers: exportedColumns.map((column) => column.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);
    // csvExporter.generateCsv(exportedData);
    // Convert data to CSV format
    const csvContent = convertToCSV(exportedData, csvOptions);

    // Create a Blob object from the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    // Create a download link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = csvOptions.fileName + '.csv';

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the file download
    link.click();

    // Clean up the temporary URL and remove the link from the document
    URL.revokeObjectURL(link.href);
    link.remove();
  };

  const convertToCSV = (data, csvOptions) => {
    const headers = csvOptions.headers.join(',') + '\n';

    const rows = data.map((row) => {
      return Object.values(row).map((value) => {
        // Handle any necessary formatting or escaping of values
        // For simplicity, we assume all values are already properly formatted

        // Wrap the value with double quotes if it contains a comma
        if (typeof value === 'string' && value.includes(',')) {
          return `"${value}"`;
        }

        return value;
      }).join(',');
    }).join('\n');

    return headers + rows;

  };
  const mobileDialogModalStyles = {
    position: 'absolute',
    left: `${scrollX}px`,
  };
  return (
    <>
      {user1.roles_id < 3 ? companyId === null ?
        <>
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
            <Grid item lg={3} md={3} sm={12} xs={12} mb={2}>
              <FormControl fullWidth margin='dense' size="small">
                <InputLabel id="demo-multiple-name-label">Assigned to</InputLabel>
                <Select
                  id="assignedto"
                  name="assignedto"
                  input={<OutlinedInput label="Assigned To" />}
                  value={assignedTo}
                  onChange={handleSelectChange}
                  MenuProps={MenuProps}
                  size='small'
                >
                    <MenuItem
                  value='unAssigned'
                >
                  unassigned
                </MenuItem>
                  <MenuItem
                    value='all'
                  >
                    All
                  </MenuItem>
                  {adminList.map((array,index) => (
                    <MenuItem
                    key={index}
                      value={array.id}
                    >
                      {array.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <FormControl fullWidth size='small' margin='dense'>
                <InputLabel id="demo-multiple-name-label">Action</InputLabel>
                <Select
                  id="assignedToAction"
                  value={assignedToAction}
                  onChange={handleActionChange}
                  MenuProps={MenuProps}
                  input={<OutlinedInput label="Action" />}
                >
                  <MenuItem key="Action" value="Action" disabled={true}>
                    Action
                  </MenuItem>
                  <MenuItem key="assignToStaff" value="assignToStaff">
                    Assign to Staff
                  </MenuItem>
                  <MenuItem key="assignToNone" value="assignToNone">
                    Assign to None
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </>
        : null : null}

      <Formik
        initialValues={{
          staffId: selected,
        }}
        validationSchema={assignedToStaffSchema}
        onSubmit={async (values, error) => {
          const loginUser = JSON.parse(localStorage.getItem('user'));
          if (loginUser.roles_id >= 3) {
            navigate('/login');
            return false;
          }
          if (selectedID.length == 0) {
            alert("Please select agent to assign");
            return false;
          }
          setSubmitEnable(true);

          const apiUrl = '/assignToMultipleMembersToAgent';
          const args = {
            assignedTo: selectedID,
            memberId: rowsSeleted,
            }
            const res = await dispatch(postData({apiUrl,args})).unwrap();
            if(res)
            {
              handleCloseAssigned();

              if(res.suspendedMessage == 1){
                 swal({
                  title: "Success",
                  text: res.message,
                  icon: "success",
                  button: "Ok",
                })
              }else{
                swal({
                  title: "Error",
                  text: res.message,
                  icon: "error",
                  button: "Ok",
                })
              }
              
              fetchReport();
              setSelectedID([]);
              setSelected([]);
              setRowSelection([]);
              setAssignedToAction('Action');
              setSubmitEnable(false);
            }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <Dialog
            open={assignedOpen}
            onClose={(_, reason) => {
              if (reason !== "backdropClick") {
                handleCloseAssigned();
              }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle textAlign={'center'} id="responsive-dialog-title" p={5} sx={{ backgroundColor: "#F6FAFF" }}>
              <Typography variant='h4' color='primary'>Assign Selected Leads To Staff</Typography>
            </DialogTitle>
            <DialogContent>
              <Grid container item lg={12} md={12} sm={12} xs={12} pt={2}>
                <FormControl className={classes.formControl} fullWidth size="small">
                  <InputLabel id="mutiple-select-label">Action</InputLabel>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    id="staffId"
                    name="staffId"
                    input={<OutlinedInput label="Action" />}
                    value={selected}
                    onChange={handleChange2}
                    renderValue={(select) =>
                      select
                        .map((id) => {
                          const selectedOption = adminList.find((option) => option.id === id);
                          return selectedOption ? selectedOption.name : '';
                        })
                        .join(", ")
                    }
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelected ? classes.selectedAll : ""
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          classes={{ indeterminate: classes.indeterminateColor }}
                          checked={isAllSelected}
                          indeterminate={
                            selected.length > 0 && selected.length < adminList.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>

                    {adminList.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        <ListItemIcon>
                          <Checkbox checked={selected.indexOf(option.id) > -1} />
                        </ListItemIcon>
                        <ListItemText primary={option.name} />
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.staffId && errors.staffId ? <div className='error'>{errors.staffId}</div> : null}
                </FormControl>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
                <Grid item lg={6} md={6} sm={6} xs={6} px={2}>
                  <Button autoFocus variant="contained" sx={{ minWidth: 250 }} onClick={() => { handleCloseAssigned() }}>
                    CLOSE
                  </Button>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} px={2}>
                  <Button variant="contained" sx={{ minWidth: 250 }} color="success" type="submit" disabled={submitEnable} onClick={handleSubmit}>
                    SAVE
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        )}
      </Formik>

      {isLoading ? <><Loader /></> : ''}
      <MaterialReactTable

        // columns={columns}
        columns={companyId === null ? columns : columns3}
        data={data}
        enableColumnFilterModes
        enableRowSelection
        filterFns={{
          customSearchFilterFn: (row, id, filterValue) =>
          row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
        }} 
        globalFilterFn="customSearchFilterFn" //exact serach filter functionality
        enableColumnFilters={config.DatatableColumnFilter}
        getRowId={(data) => data.member_id} //give each row a more useful id
        onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
        state={{ columnVisibility, rowSelection,isLoading: isLoading }} //pass our managed row selection state to the table to use
        initialState={{
          showColumnFilters: false,
          density: 'compact',
          columnPinning: { left: ['mrt-row-select','Member Id'] }
        }}
        enablePinning
        enableDensityToggle={false}
        onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
        positionToolbarAlertBanner="bottom"
        // columns={columns}
        // data={data}
        // enableColumnFilterModes
        // enableColumnOrdering
        // enableGrouping
        // enablePinning
        // enableRowSelection
        // initialState={{ showColumnFilters: false, density: 'compact' }}
        // positionToolbarAlertBanner="bottom"
        // enableDensityToggle={false}
        muiTableHeadCellProps={{
          sx: {
            backgroundColor: "#F6FAFF",
          },
        }
        }
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
          >
            <Button
              sx={{ bgColor: "#2A68B3" }}
              disabled={table.getPrePaginationRowModel().rows.length === 0}
                  
                    onClick={() =>
                      handleExportData(table.getPrePaginationRowModel().rows)
                    }
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              // onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              variant="contained"
              className='export'
            >
              CSV
            </Button>
          </Box>
        )}
      />
      {/* new activity popup start */}

      {/* new activity popup end */}
      {/* Action popup start here */}

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={opensuspended}
        onClose={handleClose1}
        aria-labelledby="responsive-dialog-title"
        style={isMobile ? mobileDialogModalStyles : null}
      >
        <Formik
          initialValues={RequestFormInitialValues}
          validationSchema={RequestFormSchema}
          onSubmit={submitRequest}
        >{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (<>
          <DialogTitle id="responsive-dialog-title">
            {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
            {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <Typography variant='h6'><b>Member Id:</b> {values.member_id}</Typography> &nbsp;&nbsp;&nbsp;
              <Typography variant='h6'><b>Member Name:</b> {values.memberName}</Typography>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <MaterialReactTable
              columns={columns1}
              data={MemberActivityList}
              enableColumnFilterModes
              enableColumnOrdering

              enableRowSelection
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              // onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{ columnVisibility, rowSelection,isLoading: isLoading }} //pass our managed row selection state to the table to use
              enableColumnFilters={config.DatatableColumnFilter}
              getRowId={(row) => row.id} //give each row a more useful id
              enableDensityToggle={false}
              initialState={{
                showColumnFilters: false,
                density: 'compact',
              }}
              onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
              positionToolbarAlertBanner="bottom"
              muiTableHeadCellProps={{
                //simple styling with the `sx` prop, works just like a style prop in this example
                sx: {
                  backgroundColor: "#F6FAFF",
                },
              }}
            />
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={3}>
              <TextareaAutosize
                style={{ width: "100%" }}
                required
                margin="dense"
                aria-label="minimum height"
                minRows={6}
                placeholder="Enter Note"
                name="note"
                id="note"
                value={values.note}
                onChange={handleChange}
              />
            </Grid>

            <Grid container item lg={12} md={12} sm={12} xs={12} id='ContactType' mt={2}>

              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Contact Type</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="callSource"
                    name="callSource"
                    label="Contact Type"
                    onChange={handleChange}
                    value={values.callSource}
                    MenuProps={MenuProps}
                >
                    
                    {contactTypeList.map((call) => (
                        <MenuItem
                            value={call.call_type}
                        >
                            {call.call_type}
                        </MenuItem>
                    ))}
                </Select>

                </FormControl>
                  {touched.callSource && errors.callSource ? <div className='error'>{errors.callSource}</div> : null}
            </Grid>

            <Grid container item lg={12} md={12} sm={12} xs={12} mt={1} spacing={2}>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <TextField
                  fullWidth
                  size='small'
                  label="Contact Reason"
                  variant="outlined"
                  margin='dense'
                  id="callType"
                  name="callType"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={values.callType}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12} >
                <FormControl fullWidth size='small' margin='dense'>
                  <InputLabel id="demo-simple-select-label">Disposition:</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="disposition"
                    name="disposition"
                    value={values.disposition}
                    label="Disposition"
                    MenuProps={MenuProps}
                    onChange={(e) => {
                      handledisposition(e.target.value);
                      handleChange(e);
                    }} >
                    {dispositionListData.map((dispositionlist) => (
                      <MenuItem
                        value={dispositionlist.id}
                      >
                        {dispositionlist.disposition}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {touched.disposition && errors.disposition ? <div className='error'>{errors.disposition}</div> : null}
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <FormControl fullWidth size='small' margin='dense'>
                  <InputLabel id="demo-simple-select-label">Outcome:</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="reason"
                    name="reason"
                    value={values.reason}
                    label="Outcome:"
                    onChange={handleChange}
                  >
                    {ReasonList.map((reason) => (
                      <MenuItem
                        value={reason.id}
                      >
                        {reason.reason}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {touched.reason && errors.reason ? <div className='error'>{errors.reason}</div> : null}
              </Grid>
            </Grid>

          </DialogContent>
          <DialogActions>
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }}>
              <Grid item lg={6} md={6} sm={6} xs={6} px={2}>
                <Button variant="contained" sx={{ minWidth: isMobile ? 100 : 250 }} onClick={handleClose1}>
                  CLOSE
                </Button>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6} px={2}>
                <Button variant="contained" sx={{ minWidth: isMobile ? 100 : 250 }} color="success" onClick={disableActivityButton === false ? handleSubmit : null}
                //  onClick={handleSubmit}
                >
                  SAVE
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>

        )}
        </Formik>
      </Dialog>
      {/* Action popup end here */}
    </>
  );
}


export default SuspendedMembersList;