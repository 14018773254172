import React, { useMemo, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Table, Alert, TableCell, Stack, Grid, TableContainer, Tooltip, TableRow, Paper, Button, Typography, Dialog,DialogContent,DialogActions } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useParams, useNavigate, Link } from 'react-router-dom';
import AuthUser from "../Auth/AuthUser";
import AiFillPhone from '@mui/icons-material/Phone';
//Icons Imports
import Moment from 'moment';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import CommonFliter from './CommonFliter';
import LinkIcon from '@mui/icons-material/Link';
import config from '../../AppConfig';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CheckIcon from '@mui/icons-material/CheckBoxOutlined';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Badge from '@mui/material/Badge';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import formatPhoneNumber from '../../../src/Components/Formats/PhoneNumberValidation';
import BounceEmailDailog from '../BounceEmail/BounceEmailDailog';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Card,CardOverflow,CardContent } from '@mui/joy';
import {openOrFocusWindow } from  '../CommonFormula';
import swal from "sweetalert";
import { useDispatch } from 'react-redux';
import {  fetchData,postData } from '../../redux/apiSlice';
function createData(name, calories, fat, carbs, protein) {
	return { name, calories, fat, carbs, protein };
}

const rows = [
	createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
];
function Completed(props) {
	const dispatch = useDispatch();
	const { tab } = useParams();
	const theme = useTheme();
	const [personName, setPersonName] = React.useState([]);
	const { http, user } = AuthUser();
	const [data, setData] = useState([]);
	const [originaldata, setOriginalData] = useState([]);
	const [rowSelection, setRowSelection] = useState({});
	const [search, setSearch] = useState("");
	const [statusMessage, setStatusMesage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [perPage, setPerPage] = useState(10);
	const [isLoading, setIsLoading] = useState(true);
	const q = search == '' ? 'null' : search;
	const [currentDate, setCurrentDate] = useState("");
	const navigate = useNavigate();
	const [columnVisibility, setColumnVisibility] = React.useState({});

	const [refresh, doRefresh] = useState(0);
	const [openCommEditEmailPopup, setopenCommEditEmailPopup] = useState(false);
	const [email,setEmail] = useState('');
	const [companyName,setcompanyName] = useState('');
	const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('md');
	const isMobile = useMediaQuery('(max-width:600px)');
	const [scrollX, setScrollX] = useState(0);
	const [companyId,setcompanyId] = useState('');
	const [communicationEmail,setcommunicationEmail] = useState('');
	const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
	const [deleteId, setDeleteId] = useState('');
	const changeRowSelection = () => {
		setRowSelection([]);
	};

	useEffect(() => {
		if (Object.values(columnVisibility).length > 0) {
			setColumnVisibility(columnVisibility);
		}
		//do something when the row selection changes...
	}, [rowSelection]);

	useEffect(() => {
		const newDate = new Date();
		newDate.setDate(newDate.getDate());
		const todaysDate = Moment(newDate).tz('America/Los_Angeles').format('MM-DD-YY hh:mm:ss');
		setCurrentDate(todaysDate);
	}, []);

	const handleChange = (event) => {
		const {
			target: { value },
		} = event;
		setPersonName(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value,
		);
	};
	const customSortFunctionCreditSnapshotStatus = (rowA, rowB) => {
		const flagPriority = {
			0: 0,
			1: 1,
		};
		const flagA = rowA.original.company.snapshot_flag;
		const flagB = rowB.original.company.snapshot_flag;
		const priorityA = flagPriority[flagA];
		const priorityB = flagPriority[flagB];
		return priorityA - priorityB;
	};

	const customSortFun = (rowA, rowB) => {
		
		const sortA = rowA.original.company.referred_by_company ? rowA.original.company.referred_by_company.company_name : rowA.original.company.referred_by != null ? rowA.original.company.referred_by : "";
		const sortB =rowB.original.company.referred_by_company ? rowB.original.company.referred_by_company.company_name : rowB.original.company.referred_by != null ? rowB.original.company.referred_by : "";
    	return sortA.localeCompare(sortB, undefined, { sensitivity: 'base' });
	};

	const editCommunicationEmailPopup = async(companyId , companyName , email , communication_email) => {
		setEmail(email);
		setcompanyName(companyName);
		setcompanyId(companyId);
		setcommunicationEmail(communication_email);
		setopenCommEditEmailPopup(true);
	};
	const editCommunicationEmailPopupClose = async() =>{
		setopenCommEditEmailPopup(false);
	};

	const mobileDialogModalStyles = {
        position: 'absolute',
        left: `${scrollX}px`,
    };

	const setErrorMessageFun = (successMessage , errorMessage) => {

        console.log("message: ", successMessage,errorMessage);
        setErrorMessage(errorMessage)
        setStatusMesage(successMessage)
        setTimeout(function () {
            setStatusMesage('')
            setErrorMessage('')
        }, 5000);
                    
    } 

	const refreshPageAfterEmailChange = () =>{
		doRefresh(prev => prev + 1);
	}


	
	const customSortFunctionReferralProgramStatus = (rowA, rowB) => {
		const conditionPriority = {
		  false: 0,
		  true: 1,
		};
	  
		const conditionA = rowA.original.company.referral_flag === 1 && rowA.original.company.referral_terms_and_conditions === 1;
		const conditionB = rowB.original.company.referral_flag === 1 && rowB.original.company.referral_terms_and_conditions === 1;
		
		const priorityA = conditionPriority[conditionA];
		const priorityB = conditionPriority[conditionB];
		
		return priorityA - priorityB;
	  };

	const customSortFunctionCobrandStatus = (rowA, rowB) => {
		const conditionPriority = {
		  false: 0,
		  true: 1,
		};
	  
		const conditionA = rowA.original.cobranding_status != null && rowA.original.cobranding_status.is_active == 1;
		const conditionB = rowB.original.cobranding_status != null && rowB.original.cobranding_status.is_active == 1;
		
		const priorityA = conditionPriority[conditionA];
		const priorityB = conditionPriority[conditionB];
		
		return priorityA - priorityB;
	};
	const deleteLog=async ()=>{
		setOpenConfirmationBox(false)
		setIsLoading(true)
		const apiUrl = '/manageLeads/delete';
		const args = {
			id: deleteId,
		}
		const res = await dispatch(postData({ apiUrl, args })).unwrap();
	  	if(res) 
		{
			doRefresh(prev => prev + 1);
			setIsLoading(false)
			if (res.success === true) {
				swal({
					title: "Success",
					text: res.message,
					icon: "success",
					button: "Ok",
				})
			} else {
				setIsLoading(false)
				swal({
				title: "error",
				text: res.message,
				icon: "error",
				button: "Ok",
				})
			}
		}
	}
	const columns = useMemo(
		//column definitions...
		() => [
			{
				accessorFn: (data) => `${data.first_name.trim() + ' ' + data.last_name.trim()}`,
				id: 'name',
				header: 'Name',
				size: 50,
				enablePinning: true,
			},
			{
				accessorFn: (data) => `${data.email ? data.email.trim() : ''} `, //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
				enableClickToCopy: true,
				id: 'email',
				header: 'Email ID',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (row.original.email ? row.original.email.trim() : "N/A"),
			},
			{
				accessorFn: (data) => `${data.communication_email ? data.communication_email.trim() : ''} `,   
				enableClickToCopy: true,
				id: 'communicationEmail',
				header: 'Communication Email ID',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<Typography>{row.original.communication_email ? 
						
						
						<span>
						
						{row.original.communication_email.trim()}
						
						{row.original.bounce_email_flag === 1 ? 
						
						<Tooltip title="Emails are not getting delivered to this address. Please update the communication email" arrow>
							
							<ErrorOutlineIcon color={'error'} 
						
						/>
						
						</Tooltip> 
						
						  : null}
						{user.roles_id < 3 && user.roles_id != 13 ?
							<Tooltip title="Edit Communication Email" arrow>
								<EditOutlinedIcon
									onClick={() => {editCommunicationEmailPopup(row.original.company.id ,row.original.company.company_name , row.original.email, row.original.communication_email)}}
								/>
							</Tooltip>
						 : null }
					   </span>:'N/A'}
					</Typography>
				),
			},
			{
				accessorKey: 'phone_no',
				accessorFn: (row) => `${row.phone_no ? formatPhoneNumber(row.phone_no.trim()) : 'N/A'}`,
				filterVariant: 'range',
				id: 'phoneNo',
				header: 'Phone no.',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					row.original.phone_no ?
						<div>
							{formatPhoneNumber(row.original.phone_no)}
							&nbsp;&nbsp;
							<span>
								{row.original.current_hours != '' ?
									row.original.current_hours >= 8 && row.original.current_hours <= 19 ?
										<Tooltip title={row.original.current_time}><WbSunnyIcon color="warning"></WbSunnyIcon ></Tooltip> : <Tooltip title={row.original.current_time}><DarkModeIcon ></DarkModeIcon></Tooltip>
									: ''
								}
							</span>
							{user.roles_id == 13?null:
							<Tooltip title="Dial" arrow>
								<a onClick={(event) => {event.preventDefault(); openOrFocusWindow(config.CallHippoDialer+row.original.phone_no); }} >
									<AiFillPhone color="primary" />
								</a>
							</Tooltip>
			}
						</div> : 'N/A'
				),
			},
			{
				accessorFn: (data) => `${data.pandadoc_id ? data.pandadoc_id : ''}`,
				filterVariant: 'range',
				id: 'docId',
				header: 'Agreement ID',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<>
						{row.original.pandadoc_id ? (
							<span>
								{row.original.pandadoc_id}
								{user.roles_id == 6 || user.roles_id == 13? null :
									<Tooltip title="Open Agreement" arrow>
										<LinkIcon color="primary" onClick={() => {
											{getPandaDoc(row.original.company.company_name) }
										}} />
									</Tooltip>
								}
							</span>
						) : "N/A"}
					</>
				),
				//custom conditional format and styling
			},
			{
				accessorFn: (data) => `${(data.company) ? (data.company.aid_master) ? data.company.aid_master.aid.trim() : '' : ''}`,
				filterVariant: 'range',
				id: 'aid',
				header: 'AID',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (row.original.company ? (row.original.company.aid_master) ? row.original.company.aid_master.aid.trim() : 'N/A' : 'N/A'),
				//custom conditional format and styling
			},
			{
				accessorFn: (data) => `${data.member_count ? data.member_count.total ? data.member_count.total : '0' : '0'}`,
				filterVariant: 'range',
				id: 'last_60_days',
				header: 'Enrollment last 60 days',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<div style={{ textAlign: "center" }}>
						<Badge badgeContent={`${row.original.member_count}` != "null" ? row.original.member_count.total : '0'} color={`${row.original.member_count}` != "null" ? 'success' : 'primary'} overlap="circular" max={9999} ></Badge>
					</div>
				),
			},
			{
				accessorFn: (data) => `${data.status ? data.status.status_name.trim() : ''}`,
				filterVariant: 'range',
				id: 'status',
				header: 'Status',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (row.original.status ? row.original.status.status_name.trim() : 'N/A'),
				//custom conditional format and styling
			},
			{
				accessorFn: (data) => `${data.lead_approved_user ? data.lead_approved_user.name.trim() : ''}`,
				filterVariant: 'range',
				id: 'ApprovedBy',
				header: 'Approved By',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<div>
						{row.original.lead_approved_user ? row.original.lead_approved_user.name.trim() : 'N/A'
						}
					</div>
				),
				//custom conditional format and styling
			},
			{
				accessorFn: (data) => `${data.lead_completed_user ? data.lead_completed_user.name : ''}`,
				filterVariant: 'range',
				id: 'completedBy',
				header: 'Completed By',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<div>
						{row.original.lead_completed_user ? row.original.lead_completed_user.name : 'N/A'
						}
					</div>
				),
				//custom conditional format and styling
			},
			{
				accessorFn: (data) => `${data.updated_at ? data.updated_at : ''}`,
				filterVariant: 'range',
				id: 'lastUpdated',
				header: 'Last Updated',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<div style={{ textAlign: "center" }}>
						{row.original.updated_at ? Moment(row.original.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : "N/A"}
					</div>
				),
				//custom conditional format and styling
			},
			{
				accessorFn: (data) => `${data.created_at ? data.created_at : ''}`,
				filterVariant: 'range',
				id: 'leadGeneratedDate',
				header: 'Lead Generated Date',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<div style={{ textAlign: "center" }}>
						{row.original.created_at ? Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') : "N/A"}
					</div>
				),
				//custom conditional format and styling
			},
			{
				accessorFn: (data) => `${data.company ? data.company.company_name : 'N/A'}`,
				id: 'company_name',
				header: 'Company Name',
				size: 50,
			},
			{
				accessorFn: (data) => `${data.last_activity_logs ? data.last_activity_logs.type ? data.last_activity_logs.type.trim() : '' : ''}`,
				filterVariant: 'range',
				id: 'followup',
				header: 'Followup',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<>
						{row.original.last_activity_logs ? row.original.last_activity_logs.type ? row.original.last_activity_logs.type.trim() : "N/A" : "N/A"}
					</>
				),
				//custom conditional format and styling
			},
			{
				accessorFn: (data) => `${data.company.referred_by_company ? data.company.referred_by_company.company_name : data.company.referred_by ? data.company.referred_by : ""}`,
				filterVariant: 'range',
				id: 'leadType',
				header: 'Lead Type',
				size: 50,
				sortingFn: customSortFun,
				Cell: ({ renderedCellValue, row }) => (row.original.company.referred_by_company ? row.original.company.referred_by_company.company_name : row.original.company.referred_by ? row.original.company.referred_by : "N/A"),
				//custom conditional format and styling
			},
			{
				accessorFn: (data) => `${data.company ? data.company.marketing_type?data.company.marketing_type : '': ''}`,
				filterVariant: 'range',
				id: 'softwareUsed',
				header: 'Software Used',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<div className='datatable'>
						{row.original.company ? row.original.company.marketing_type?row.original.company.marketing_type : "N/A": "N/A"}
					</div>
				),
				//custom conditional format and styling
			},
			{
				accessorFn: (data) => `${data.last_activity_logs ? data.last_activity_logs.notes ? data.last_activity_logs.notes.trim() : '' : ''}`,
				filterVariant: 'range',
				id: 'comment',
				header: 'Comment',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<Box className="datatable">
						<Typography>{row.original.last_activity_logs ? row.original.last_activity_logs.notes ? row.original.last_activity_logs.notes.trim() : 'N/A' : 'N/A'}</Typography>
					</Box>
				),
			},
			{
				accessorFn: (data) => `${data.last_activity_logs ? data.last_activity_logs.add_next_followup : ''}`,
				filterVariant: 'range',
				id: 'nextFollowup',
				header: 'Next Followup',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<div>
						{row.original.last_activity_logs && row.original.last_activity_logs.add_next_followup !== "0000-00-00 00:00:00" && row.original.last_activity_logs.add_next_followup !== null
							? Moment(row.original.last_activity_logs.add_next_followup).format('MM-DD-YYYY H:mm')
							: "N/A"
						}
					</div>
				),
				//custom conditional format and styling
			},
			{
				accessorFn: (data) => `${data.lead_assigned_user ? data.lead_assigned_user.name.trim() : ''}`,
				filterVariant: 'range',
				id: 'Assigned To',
				header: 'Assigned To',
				size: 50,
				Cell: ({ renderedCellValue, row }) => (
					<div>
						{row.original.lead_assigned_user ? row.original.lead_assigned_user.name.trim() : 'N/A'
						}
					</div>
				),
			},
			{
				accessorFn: (data) => `${data}`,
				filterVariant: 'range',
				id: 'creditSnapshotStatus',
				header: 'Credit Snapshot Status',
				size: 150,
				sortingFn: customSortFunctionCreditSnapshotStatus,
				Cell: ({ row }) => (
					<>
						<Stack direction="row">
							<Box>
								{`${row.original.company.snapshot_flag}` == "1" ?
									<CheckIcon color="success" /> :
									<CheckIcon color="disabled" />}
							</Box>
							<Box>
								{`${row.original.company.snapshot_flag}` != 1 ?
									<>
										{`${row.original.credit_snapshot_activity_logs}` != "null" ?
											<>
												{`${row.original.credit_snapshot_activity_logs.act_creditsnapshot_status}` == "Initiated" ?
													<>
														<Tooltip title="Initiated" arrow><FlagRoundedIcon color="success"></FlagRoundedIcon></Tooltip>
													</>
													:
													`${row.original.credit_snapshot_activity_logs.act_creditsnapshot_status}` == "Declined" ?
														<>
															<Tooltip title="Declined" arrow><CloseRoundedIcon sx={{ color: "red" }}></CloseRoundedIcon></Tooltip>
														</>
														:
														`${row.original.credit_snapshot_activity_logs.act_creditsnapshot_status}` == "Deferred" ?
															<>
																<Tooltip title={"Deferred till " + Moment(`${row.original.last_activity_logs.add_next_followup}`).format('MM-DD-YYYY H:mm')} arrow><AccessTimeIcon sx={{ color: "brown" }}></AccessTimeIcon></Tooltip>
																{
																	Moment(`${row.original.last_activity_logs.add_next_followup}`).isBefore(currentDate) ? <Tooltip arrow><FlagRoundedIcon sx={{ color: "orange" }}></FlagRoundedIcon></Tooltip> : ''
																}
															</>
															: ''
												}
											</>
											: ''
										}
									</>

									: ''}
							</Box>
						</Stack>
					</>
				),

			},
			{
				accessorFn: (data) => `${data}`,
				filterVariant: 'range',
				id: 'referralProgramStatus',
				header: 'Referral Program Status',
				sortingFn: customSortFunctionReferralProgramStatus,
				size: 50,
				Cell: ({ row }) => (
					<Box>
						{`${row.original.company.referral_flag}` == 1 && `${row.original.company.referral_terms_and_conditions}` == 1 ?
							<Tooltip title="Terms and Conditions Accepted" arrow>
								<CheckIcon color="success" /></Tooltip> :
							<>
								<CheckIcon color="disabled" />
								{`${row.original.reference_activity_logs}` != "null" ?
									<>
										{`${row.original.reference_activity_logs.act_referral_program_status}` == "Initiated" ?
											<>
												<Tooltip title="Initiated" arrow><FlagRoundedIcon color="success"></FlagRoundedIcon></Tooltip>
											</>
											:
											`${row.original.reference_activity_logs.act_referral_program_status}` == "Declined" ?
												<>
													<Tooltip title="Declined" arrow><CloseRoundedIcon sx={{ color: "red" }}></CloseRoundedIcon></Tooltip>
												</>
												:
												`${row.original.reference_activity_logs.act_referral_program_status}` == "Deferred" ?
													<>
														<Tooltip title={"Deferred till " + Moment(`${row.original.reference_activity_logs.add_next_followup}`).format('MM-DD-YYYY H:mm')} arrow><AccessTimeIcon sx={{ color: "brown" }}></AccessTimeIcon></Tooltip>
														{
															Moment(`${row.original.reference_activity_logs.add_next_followup}`).isBefore(currentDate) ? <Tooltip arrow><FlagRoundedIcon sx={{ color: "orange" }}></FlagRoundedIcon></Tooltip> : ''
														}
													</> : ''
										}
									</> : ''}

							</>
						}
					</Box>
				),
			},

			{
				accessorFn: (data) => `${data}`,
				filterVariant: 'range',
				id: 'cobrandingStatus',
				header: 'Cobranding Status',
				sortingFn : customSortFunctionCobrandStatus,
				size: 150,
				// sortingFn: customSortFunctionCobrandingStatus,
				Cell: ({ row }) => (
					<>
						<Stack direction="row">
							<Box>
								{
									`${row.original.cobranding_status}` != "null" && `${row.original.cobranding_status.is_active}` == "1" ?
										<>
											<CheckIcon color="success" />
										</>
										:
										<>
											<CheckIcon color="disabled" />
											{`${row.original.cobrand_activity_logs}` != "null" && ((`${row.original.cobranding_status}` == "null") || ((`${row.original.cobrand_activity_logs}` != "null") && (`${row.original.cobranding_status}` != "null") && Moment(`${row.original.cobranding_status.updated_at}`)).isBefore(Moment(`${row.original.cobrand_activity_logs.created_at}`))) ?
												<>
													{`${row.original.cobrand_activity_logs.act_cobranding_status}` == "Initiated" ?
														<>
															<Tooltip title="Initiated" arrow><FlagRoundedIcon color="success"></FlagRoundedIcon></Tooltip>
														</>
														:
														`${row.original.cobrand_activity_logs.act_cobranding_status}` == "Declined" ?
															<>
																<Tooltip title="Declined" arrow><CloseRoundedIcon sx={{ color: "red" }}></CloseRoundedIcon></Tooltip>
															</>
															:
															`${row.original.cobrand_activity_logs.act_cobranding_status}` == "Deferred" ?
																<>
																	<Tooltip title={"Deferred till " + Moment(`${row.original.last_activity_logs.add_next_followup}`).format('MM-DD-YYYY H:mm')} arrow><AccessTimeIcon sx={{ color: "brown" }}></AccessTimeIcon></Tooltip>
																	{
																		Moment(`${row.original.last_activity_logs.add_next_followup}`).isBefore(currentDate) ? <Tooltip arrow><FlagRoundedIcon sx={{ color: "orange" }}></FlagRoundedIcon></Tooltip> : ''
																	}
																</> : ''
													}
												</>

												: ''}
										</>
								}
							</Box>
						</Stack>
					</>
				),
				//custom conditional format and styling
			},
			{
				accessorFn: (data) => <>
					{
						 user.roles_id=== 13 ?
						 ( <Tooltip title="Delete" arrow >
						   <img src={require('../../assets/icons/deleteIcon2.png')}  onClick={()=>{setDeleteId(data.company_master_id);setOpenConfirmationBox(true)}}/>
						   </Tooltip>):
					user.roles_id == 6 ? null :
						<Tooltip title="Affiliate Details" arrow><Link className='btn btn-sm btn-info text-white' to={{ pathname: "/crcreport/" + data.id }} ><img src={require('../../assets/images/details.png')} /></Link></Tooltip>}&nbsp;
				</>,
				id: 'actions',
				header: 'Actions',
				enableSorting: false
			},
		]
	)


	const ChangeEnrollmentFilter = (count) => {
		var max= 0; var min = 0; var filteredData = [];
		switch(count) {
			case 'all' :filteredData = originaldata; break;
			case '0' : 
						max= 0; var min = 0; 
						filteredData = originaldata.filter((item) => item.member_count == null ? item : '');
						break;
			case '5' : 
					   max= 5; var min = 1; 
					   filteredData = originaldata.filter((item) =>item.member_count != null ? item.member_count.total <= max &&  item.member_count.total >= min ? item : '' : '');
					   break;
			case '6' : 
					    max= 20; var min = 6; 
						filteredData = originaldata.filter((item) =>item.member_count != null ? item.member_count.total <= max &&  item.member_count.total >= min ? item : '' : '');
					   break;
			case '20' : 
						max= 20000; var min = 21; 
						filteredData = originaldata.filter((item) =>item.member_count != null ? item.member_count.total <= max &&  item.member_count.total >= min ? item : '' : '');
						break;
		}
		setData(filteredData);
	}

	const handleReferredList = (data) => {
		setData(data);
		setOriginalData(data);
		setIsLoading(false);
		props.freezStop();
	}

	const handleTablePreferences = (data) => {
		if (Object.values(data).length > 0) {
			setColumnVisibility(data);
		}
		// setIsLoading(false);
		props.freezStop();
	}

	const startLoading = () => {
		setIsLoading(true);
		props.freezStart();
	}

	const getPandaDoc = async (name) => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  > 2) {
			navigate('/login');
			return false;
		}
		const apiUrl = `/get-pdf/${name}`;
		window.open(config.API_BASE_URL+apiUrl); 
        // const res = await dispatch(fetchData(apiUrl)).unwrap();
		// if(res)
		// {	
		// 	if (res.success) {
		// 		window.open(config.BACKEND_URL+res.data.pdfUrl); 
		// 	}else {
		// 		swal({
		// 			title: "error",
		// 			text: res.message,
		// 			icon: "error",
		// 			button: "Ok",
		// 		})
		// 	}
		// }
	}

	// csv option start here
	const csvOptions = {
		fieldSeparator: ',',
		quoteStrings: '"',
		decimalSeparator: '.',
		showLabels: true,
		useBom: true,
		useKeysAsHeaders: false,
		fileName: 'Completed Leads',
		headers: ['Name', 'Email ID', 'Phone No.','Communication Email ID', 'Agreement ID', 'AID', 'Enrollment last 60 days', 'Status', 'Approved By', 'Completed By', 'Last Updated', 'Lead Generated Date','Company Name','Followup', 'Lead Type', 'Software Used', 'Comment', 'Next Followup','Assigned To', 'credit Snapshot Status', 'credit Snapshot By', 'Referral Program Status', 'Referral Program By', 'Cobranding Status', 'Cobranding Verified By'],
	};

	const csvExporter = new ExportToCsv(csvOptions);
	const handleExportRows = (rows) => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  > 2 && user.roles_id != 13) {
			navigate('/login');
			return false;
		}
		const exportedData = rows.map((row) => {
			const name = `${row.original.first_name} ${row.original.last_name}` || "N/A";
			const email_id = `${row.original.email}` || "N/A";
			const phone = `${formatPhoneNumber(row.original.phone_no)}` || "N/A";
			const communication_email=`${row.original.communication_email}`||"N/A";
			const doc_id = `${row.original.pandadoc_id}` || "N/A";
			const aid = row.original.company?.aid_master?.aid || "N/A";
			const last_60_days_enrollment = `${row.original.member_count != null ? row.original.member_count.total : 0}`;
			const status = row.original.status?.status_name || "N/A";
			const approvedBy = row.original.lead_approved_user?.name || "N/A";
			const completedBy = row.original.lead_completed_user?.name || "N/A";
			const last_updated = Moment(row.original.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') || "N/A";
			const lead_generated_date = Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm') || "N/A";
			const company_name = `${row.original.company ? row.original.company.company_name : 'N/A'}`;
			const followup = row.original.last_activity_logs?.type || "N/A";
			const lead_type = `${
				row.original.company &&
				(row.original.company.referred_by_company
				  ? row.original.company.referred_by_company.company_name
				  : row.original.company.referred_by != null
				  ? row.original.company.referred_by
				  : "N/A")
			  }`;
			const software_used = row.original.company?.marketing_type || "N/A";
			const comment = row.original.last_activity_logs?.notes.replace(/[\r\n]+/g, '') || "N/A";
			const next_followup = row.original.last_activity_logs && row.original.last_activity_logs.add_next_followup !== "0000-00-00 00:00:00" && row.original.last_activity_logs.add_next_followup !== null
			? Moment(row.original.last_activity_logs.add_next_followup).format('MM-DD-YYYY H:mm')
			: "N/A";
			const assigned_to =  row.original.lead_assigned_user ? row.original.lead_assigned_user.name : "N/A";
			const creditSnapshotStatus = `${
				row.original.company &&
				row.original.company.snapshot_flag == 1
				  ? "Enabled"
				  : "Pending"
			  }`;
			const creditSnapshotBy = `${
				row.original.company &&
				row.original.company.snapshot_flag === 1 &&
				row.original.credit_snapshot_by &&
				row.original.credit_snapshot_by.event_by_user
				  ? row.original.credit_snapshot_by.event_by_user.name || "N/A"
				  : "N/A"
			  }`;
			const referralStaus = `${
				row.original.company &&
				row.original.company.referral_flag === 1 &&
				row.original.company.referral_terms_and_conditions === 1
				  ? "Enabled"
				  : "Pending"
			  }`;
			const referralProgramBy = `${
				row.original.company &&
				row.original.company.referral_flag === 1 &&
				row.original.company.referral_terms_and_conditions === 1 &&
				row.original.company.company_affiliate
				  ? row.original.company.company_affiliate.name || "N/A"
				  : "N/A"
			  }`;
			const cobrandStatus = `${(row.original.cobranding_status != null ? row.original.cobranding_status.status : "Pending")}`;
			const cobrandVerifiedBy = `${(row.original.cobranding_status === null) ? "N/A" : row.original.cobranding_status.verified_by != null ? row.original.cobranding_status.verified_by.name : "N/A"}`;
			return {
				name,
				email_id,
				phone,
				communication_email,
				doc_id,
				aid,
				last_60_days_enrollment,
				status,
				approvedBy,
				completedBy,
				last_updated,
				lead_generated_date,
				company_name,
				followup,
				lead_type,
				software_used,
				comment,
				next_followup,
				assigned_to,
				creditSnapshotStatus,
				creditSnapshotBy,
				referralStaus,
				referralProgramBy,
				cobrandStatus,
				cobrandVerifiedBy
			};
		});

		// csvExporter.generateCsv(exportedData);
		// Convert data to CSV format
		const csvContent = convertToCSV(exportedData);

		// Create a Blob object from the CSV data
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

		// Create a download link element
		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		link.download = csvOptions.fileName + '.csv';

		// Append the link to the document body
		document.body.appendChild(link);

		// Programmatically click the link to trigger the file download
		link.click();

		// Clean up the temporary URL and remove the link from the document
		URL.revokeObjectURL(link.href);
		link.remove();
	};

	const convertToCSV = (data) => {
		const headers = csvOptions.headers.join(',') + '\n';

		const rows = data.map((row) => {
			return Object.values(row).map((value) => {
			  // Handle any necessary formatting or escaping of values
			  // For simplicity, we assume all values are already properly formatted
		
			  // Replace newline characters with spaces
			  if (typeof value === 'string') {
				value = value.replace(/\n/g, ' '); // Replace newline characters with spaces
			  }
		
			  // Wrap the value with double quotes if it contains a comma or quote
			  if (typeof value === 'string' && (value.includes(',') || value.includes('"'))) {
				return `"${value.replace(/"/g, '""')}"`; // Escape double quotes by doubling them
			  }
		
			  return value;
			}).join(',');
		  }).join('\n');
		
		  return headers + rows;
		};
	// csv end here
	return (
		<>
			<Box pb={2} sx={{ width: '100%' }}>
				{statusMessage ? <Alert severity="success">{statusMessage}</Alert> : ''}
				{errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
			</Box>
			<CommonFliter http={http} leadType={tab} perPage={perPage}
				rowSelection={rowSelection}
				onDataChange={handleReferredList}
				onTablePreferences={handleTablePreferences}
				refresh={refresh}
				userId={user.id}
				columnArray={columnVisibility}
				changeRowSelection={changeRowSelection}
				startLoading={startLoading}
				onChangeEnrollmentFilter= {ChangeEnrollmentFilter}
			/>
			<Grid mt={2}>
				<MaterialReactTable
					columns={columns}
					data={data}
					filterFns={{
						customSearchFilterFn: (row, id, filterValue) =>
						row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
					  }}   
					  globalFilterFn="customSearchFilterFn" //exact serach filter functionality
					enableColumnFilterModes
					enablePinning
					enableRowSelection={user.roles_id != 13}
					enableColumnFilters={config.DatatableColumnFilter}
					getRowId={(row) => row.id} //give each row a more useful id
					onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
					state={{ columnVisibility, rowSelection, isLoading: isLoading }} //pass our managed row selection state to the table to use
					initialState={{
						showColumnFilters: false,
						density: 'compact',
						columnPinning: { left: ['mrt-row-expand','mrt-row-select', 'name'] }
					}}
					onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
					positionToolbarAlertBanner="bottom"
					enableDensityToggle={false}

					renderDetailPanel={({ row }) => (
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-around',
								alignItems: 'center',
							}}
						>
							<TableContainer component={Paper}>
								<Table className='back'>
									<TableRow>
										<TableCell style={{ fontWeight: 'bold' }}>Name:</TableCell>
										<TableCell>{row.original.first_name + ' ' + row.original.last_name}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Email:</TableCell>
										<TableCell>{row.original.email}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Phone No:</TableCell>
										<TableCell>{formatPhoneNumber(row.original.phone_no)}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell style={{ fontWeight: 'bold' }}>AID:</TableCell>
										<TableCell>{row.original.company ? row.original.company.aid_master ? row.original.company.aid_master.aid : 'N/A' : 'N/A'}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Agreement ID:</TableCell>
										<TableCell>{row.original.pandadoc_id}
											{user.roles_id == 6 ||user.roles_id=== 13? null :
												<Tooltip title="View Agreement" arrow><LinkIcon color="primary" onClick={() => {
													{getPandaDoc(row.original.company.company_name) }
												}} /></Tooltip>}
										</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Status:</TableCell>
										<TableCell>{row.original.status.status_name}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell style={{ fontWeight: 'bold' }}>Last Updated:</TableCell>
										<TableCell>{Moment(row.original.updated_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm')}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Lead Generated Date:</TableCell>
										<TableCell>{Moment(row.original.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY H:mm')}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Followup:</TableCell>
										<TableCell>{(row.original.last_activity_logs ? (row.original.last_activity_logs.type ? row.original.last_activity_logs.type : 'N/A') : 'N/A')}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell style={{ fontWeight: 'bold' }}>Lead Type:</TableCell>
										<TableCell>{row.original.company.referred_by_company ? row.original.company.referred_by_company.company_name : row.original.company.referred_by != null ? row.original.company.referred_by : "N/A"}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Software Used:</TableCell>
										<TableCell>{row.original.company ? row.original.company.marketing_type : 'N/A'}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Comments:</TableCell>
										<TableCell>{data.last_activity_logs ? row.original.last_activity_logs.notes ? row.original.last_activity_logs.notes : 'N/A' : 'N/A'}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell style={{ fontWeight: 'bold' }}>Next Followup:</TableCell>
										<TableCell>{row.original.last_activity_logs ? row.original.last_activity_logs.add_next_followup ? row.original.last_activity_logs.add_next_followup : 'N/A' : 'N/A'}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Assigned To:</TableCell>
										<TableCell>{row.original.lead_assigned_user ? row.original.lead_assigned_user.name : 'N/A'}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Approved By:</TableCell>
										<TableCell>{row.original.lead_approved_user ? row.original.lead_approved_user.name : 'N/A'}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell style={{ fontWeight: 'bold' }}>Completed By:</TableCell>
										<TableCell>{row.original.lead_approved_user ? row.original.lead_approved_user.name : 'N/A'}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Completed Reason:</TableCell>
										<TableCell>{row.original.completed_reason ? row.original.completed_reason : 'N/A'}</TableCell>
										<TableCell style={{ fontWeight: 'bold' }}>Enrollments Last 60 Days:</TableCell>
										<TableCell>{'N/A'}</TableCell>
									</TableRow>
								</Table>
							</TableContainer>
						</Box>
					)}

					//top csv option
					renderTopToolbarCustomActions={({ table }) => (
						<Box
							sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
						>
							<Button
								sx={{ bgColor: "#2A68B3" }}
								//export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
								disabled={table.getPrePaginationRowModel().rows.length === 0}
								//export all rows, including from the next page, (still respects filtering and sorting)
									onClick={() =>
										handleExportRows(table.getPrePaginationRowModel().rows)
									}
								startIcon={<FileDownloadIcon />}
								variant="contained"
								className='export'
							>
								CSV
							</Button>
						</Box>
					)
					}
				/>
			</Grid>
			
			<Dialog
					onClose={(_, reason) => {
						if (reason !== "backdropClick") {
							editCommunicationEmailPopupClose();
						}
					}}
					fullWidth={fullWidth}
					maxWidth={maxWidth}
					open={openCommEditEmailPopup}
					aria-labelledby="responsive-dialog-title"
					style={isMobile ? mobileDialogModalStyles : null}
				>
					
					<Card>
						<CardOverflow
							sx={{
								bgcolor:'#F6FAFF',
								borderColor:'#F6FAFF',
								padding: '1%',
							}}>
								{statusMessage ? <Alert severity="success">{statusMessage}</Alert> : ''}
								{errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
								<Grid container item lg={12} md={12} sm={12} xs={12}>
								<Typography gutterBottom color="primary" variant='h5'><b>Update communication email</b></Typography> 
								</Grid>
						</CardOverflow>
						<CardContent>
							
							<Grid container item lg={12} md={12} sm={12} xs={12}>
								<Grid item lg={4} md={4} sm={4} xs={4}>
									<Typography  variant="h6" gutterBottom>Company Name:
									</Typography>
								</Grid>
                            <Grid item lg={8} md={8} sm={8} xs={8}>
							<Typography  variant="h6" gutterBottom><b>{companyName }</b></Typography>
                                    </Grid>
                                </Grid>
								<Grid container item lg={12} md={12} sm={12} xs={12}>
							<Grid item lg={4} md={4} sm={4} xs={4}>
								<Typography  variant="h6" gutterBottom>Registered Email:
								</Typography>
								</Grid>
								<Grid item lg={8} md={8} sm={8} xs={8}>
										<Typography  variant="h6" gutterBottom><b>{email}</b></Typography>
								</Grid>
							</Grid>
						<BounceEmailDailog  id={companyId} email={email} communication_email = {communicationEmail} onValueChange={setErrorMessageFun} closeModal = {editCommunicationEmailPopupClose} fetchData = {refreshPageAfterEmailChange}/>
					</CardContent>
					</Card>
					</Dialog>
					<Dialog
                                fullWidth
                                maxWidth={'sm'}
                                open={openConfirmationBox}
                                onClose={(_, reason) => {
                                    if (reason !== "backdropClick") {
                                        setOpenConfirmationBox(false);
                                    }
                                }}
                            >
                 
                                <DialogContent>
                                    <Box mt={1} sx={{textAlign:'center'}}>
                                        <Typography variant='h5'>Are you sure you want to delete this lead?</Typography>
                                    </Box>
                                </DialogContent>
                                <DialogActions sx={{ margin: 'auto' }} >
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" onClick={()=>{deleteLog()}} color="success" sx={{ minWidth: 250 }} >
                                            Delete
                                        </Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" onClick={() => setOpenConfirmationBox(false)} color="primary" sx={{ minWidth: 250 }}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                </DialogActions>
                    </Dialog>
		</>
	);

}


export default Completed;